import React, { useMemo } from 'react';
import { FieldSelectEditor } from './FieldSelectEditor';
import { PanelPlugin } from '@grafana/data';
import { helpManagerPanel } from './HelpManagerPanel';
import { HelpPanelOptions } from './types';

export const plugin = new PanelPlugin<HelpPanelOptions>(helpManagerPanel).setPanelOptions((builder) => {
  return builder
    .addCustomEditor({
      id: 'uid',
      path: 'uid',
      name: 'UID Field',
      defaultValue: 'uid',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'title',
      path: 'title',
      name: 'Title Field',
      defaultValue: 'title',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'technicalDetails',
      path: 'technicalDetails',
      name: 'Technical details Field',
      description: 'Technical details in JSON format',
      defaultValue: 'tech_details',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'updated_at',
      path: 'updated_at',
      name: 'Last Update Field',
      defaultValue: 'updated_at',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'created_at',
      path: 'created_at',
      name: 'Creation Date Field',
      defaultValue: 'created_at',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'user',
      path: 'user',
      name: 'User Field',
      description: 'The user who modified the element for the last time',
      defaultValue: 'user',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'version',
      path: 'version',
      name: 'Version Field',
      description: 'The number of versión for this IDX',
      defaultValue: 'version',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'reference',
      path: 'reference',
      name: 'Reference Field',
      defaultValue: 'reference',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })


    .addTextInput({
      path: 'api',
      name: 'API URL',
      category: ['General Settings'],
      defaultValue: '',
    })
    .addTextInput({
      path: 'imageServer',
      name: 'NetImage Server URL',
      category: ['General Settings'],
      defaultValue: '',
    })
    .addTextInput({
      path: 'maxImageSize',
      name: 'Max image size',
      category: ['General Settings'],
      defaultValue: '',
    })
    .addTextInput({
      path: 'maxFileSize',
      name: 'Max doc file size',
      category: ['General Settings'],
      defaultValue: '',
    })
    .addBooleanSwitch({
      path: 'showOnlyInUse',
      name: 'Show only In use doc',
      category: ['General Settings'],
      description: 'Plugin show only documents with "In use" state',
	  defaultValue: false,
    })
    .addTextInput({
      path: 'variableToTrack',
      name: 'Main filter variable to Track',
      description: 'This field is used to determine if plugin need to be re-renderized',
      defaultValue: '',
      category: ['General Settings'],
    })
    .addTextInput({
      path: 'uidVariable',
      name: 'UID Variable',
      category: ['General Settings'],
      description: 'UID variable to filter data. If typeVariable = "notes" and Uid variable = "newId", then a new record is save',
	  defaultValue: '',
    })
    .addTextInput({
      path: 'hashVariable',
      name: 'HASH Variable',
      category: ['General Settings'],
      description: 'Type variable to filter data',
	  defaultValue: '',
    })
    .addTextInput({
      path: 'typeVariable',
      name: 'Message Type Variable',
      category: ['General Settings'],
      description: 'Type variable to filter data',
	  defaultValue: '',
    })
    .addTextInput({
      path: 'dashboardIdVariable',
      name: 'Dashboard ID Variable',
      category: ['General Settings'],
      description: 'Dashboard ID variable to attached a note or filter data.',
	  defaultValue: '',
    })
    .addTextInput({
      path: 'alarmIdVariable',
      name: 'Alarm ID Variable',
      category: ['General Settings'],
      description: 'Alarm ID variable to attached a note or filter data.',
	  defaultValue: '',
    })
    .addTextInput({
      path: 'siteIdVariable',
      name: 'Site Doc ID Variable',
      category: ['General Settings'],
      description: 'Site ID variable to attached doc or filter data.',
	  defaultValue: '',
    })
    .addTextInput({
      path: 'assetIdVariable',
      name: 'Asset ID Variable',
      category: ['General Settings'],
      description: 'Asset ID variable to attached doc or filter data.',
	  defaultValue: '',
    })
    .addBooleanSwitch({
      path: 'viewerMode',
      name: 'Viewer mode',
      description: 'Show Doc in viewer mode',
      category: ['Options'],
      defaultValue: false,
    })
    .addBooleanSwitch({
      path: 'editorCanAdmin',
      name: 'Editors can admin?',
      description: 'Enable editor to add, modify and delete docs',
      category: ['Options'],
      showIf: (options) => options.viewerMode === false,
      defaultValue: false,
    })
    .addBooleanSwitch({
      path: 'canAddNotes',
      name: 'Viewers can add notes?',
      description: 'Enable viewer to add, modify and delete notes',
      category: ['Options'],
      showIf: (options) => options.viewerMode === false,
      defaultValue: true,
    })
    .addBooleanSwitch({
      path: 'showTableMode',
      name: 'show in ToC mode',
      description: 'Enable plugin to work in ToC mode for viewers',
      category: ['Options'],
      showIf: (options) => options.viewerMode === false,
      defaultValue: true,
    })
    .addBooleanSwitch({
      path: 'showTitle',
      name: 'show customizable title',
      description: 'Enable title inside plugin',
      category: ['Options'],
      showIf: (options) => options.viewerMode === false,
      defaultValue: true,
    })
    .addBooleanSwitch({
      path: 'showSelector',
      name: 'show filter selector',
      description: 'Enable filter selector inside plugin',
      category: ['Options'],
      showIf: (options) => options.viewerMode === false,
      defaultValue: true,
    })
    .addBooleanSwitch({
      path: 'saniticeDoc',
      name: 'Sanitice document',
      description: 'Enable markdown sanitization',
      category: ['Options'],
      showIf: (options) => options.viewerMode === false && options.showTableMode === false,
      defaultValue: false,
    })
    .addBooleanSwitch({
      path: 'enablePreview',
      name: 'Enable Preview',
      description: 'Enable markdown preview',
      category: ['Options'],
      showIf: (options) => options.viewerMode === false && options.showTableMode === false,
      defaultValue: false,
    })
    .addBooleanSwitch({
      path: 'previewOnly',
      name: 'Only Preview mode',
      description: 'Enable only preview mode',
      category: ['Options'],
      showIf: (options) => options.viewerMode === false,
      defaultValue: false,
    })
    .addTextInput({
      name: 'Title',
      path: 'pluginTitle',
      defaultValue: 'Editor de Documentos de NetMonitor',
      category: ['Options'],
      showIf: (options) => options.showTitle === true,
    })
    .addTextInput({
      name: 'Success Massage',
      path: 'successMessage',
      defaultValue: 'Documentos modificado exitosamente',
      category: ['Options'],
      showIf: (options) => options.showTitle === true,
    })
    .addTextInput({
      path: 'error4',
      name: 'Size error message',
      category: ['Error Messages'],
      defaultValue: 'Error: contenedor demasiado pequeño',
    });
});
