import React, { FC, useEffect, useRef, useState } from 'react';
import { TemplateSrv, locationService, getTemplateSrv, config, SystemJS } from '@grafana/runtime';
import L, { LatLng } from 'leaflet';
import 'leaflet-kmz';
import './css/leaflet.css';
import { systemIcons } from './Icons';
import {valueToSubtype } from './types';

interface MapComponentProps {
  renderCount: number;
  items: Array<DynamicTableItemProps<T>>;
  currentNodes: [];
  onCoordinatesChange: (coords: [number, number], currentPlace: string) => void;
  onLineChanges: (coords: [], currentUid: string) => void;
  onImportData: (newElements: [], file: any) => void;
  onSelectionChange: (uid: string) => void;
  onPosChange: (center: any, zoom: number) => void;
  markers: [];
  showMarkerIcons: boolean;
  mapSource: string;
  geoVariables: [boolean, string, string, string, string];
  readOnly: boolean;
  tableMode: boolean;
  isKmzAddMode: any;
  zoom: number,
  center: any;
}

export const MapComponent: FC<MapComponentProps> = ({ 
  renderCount,
  items,
  currentNodes,
  onCoordinatesChange,
  onLineChanges,
  onImportData,
  onSelectionChange,
  onPosChange,
  markers,
  showMarkerIcons,
  mapSource,
  geoVariables,
  readOnly,
  tableMode,
  isKmzAddMode,
  zoom,
  center
}) => {
  const mapRef = useRef<HTMLDivElement | null>(null);
  const leafRef = useRef<L.Map | null>(null);
  const markerRef = useRef<L.Marker | null>(null);
  const kmzRef = useRef<L.kmzLayer | null>(null);
  var leaf: any = leafRef.current;
  //var marker: any = markerRef.current;
  var kmz: any = kmzRef.current;
  var control: any;
  var currentLayer: any;
  var pointLayer: any;
  var objectBounds: [] = [];
  var selectionBounds: [] = [];

  const base = 'https://services.arcgisonline.com/ArcGIS/rest/services/';
  const arcgisUrl = `${base}${mapSource}/MapServer/tile/{z}/{y}/{x}`;
  const attribution = `Tiles © <a href="${base}${mapSource}/MapServer">ArcGIS</a>`;
  const initialZ = geoVariables[0] ? Number(getTemplateSrv().replace(`$${geoVariables[1]}`)) : 12;
  const initialX = geoVariables[0] ? Number(getTemplateSrv().replace(`$${geoVariables[2]}`)) : 0;
  const initialY = geoVariables[0] ? Number(getTemplateSrv().replace(`$${geoVariables[3]}`)) : 0;
  const initialPosition = (initialX !== 0 && initialY !== 0 && initialZ !== 12) ? true : false;
  const [hasPosition, setHasPosition] = useState(initialPosition);
  const [actualZ, setActualZ] = useState(initialZ);
  const [actualX, setActualX] = useState(initialX);
  const [actualY, setActualY] = useState(initialY);

  //console.log('Elemento seleccionado: ', markers, initialX, initialY, initialZ);
  //console.log(currentNodes);
  //console.log(hasPosition, actualZ, actualX, actualY);

  const familyToString = (value: string) => {
	const valueSelected = valueToSubtype.find(ele => ele.value === value);
	if (valueSelected) {
	  return valueSelected.label;
	} else {
	 return null;
	}
  }

  const getBoundsArea = (bounds) => {
	if (bounds !== null) {
	  const southWest = bounds.getSouthWest();
      const northEast = bounds.getNorthEast();
      const width = Math.abs(northEast.lng - southWest.lng);
      const height = Math.abs(northEast.lat - southWest.lat);
      return width * height;
	} else {
	  return 0;
	}
  };

  const findLargestBounds = (boundsArray) => {
    const areas = boundsArray.map(getBoundsArea);
    const maxArea = Math.max(...areas);
    return boundsArray[areas.indexOf(maxArea)];
  };

  const mapOptions = {
	center: { lat: actualX, lng: actualY },
	zoom: initialZ,
	minZoom: 3,
	maxZoom: 19,
	zoomControl: false,
	boxZoom: true,
	scrollWheelZoom: true,
	touchZoom: true,
  };

  const toLatLng = (lat, lng) => [parseFloat(lat), parseFloat(lng)];

  const drawMarkers = (places: [], items: [], map: L.Map, controlLayer: any, layerName: string, variable: string) => {
    const elementLayer = L.featureGroup({ interactive: true });
	const bounds = L.latLngBounds();
	const markerLines = new Map();
	const allLines = [];
	let originalPosition = null;
    if (!places || !map) {
	  return null;
	}
	places.forEach(place => {
      if (place) {
        const iconType = place.family;
		const path = items.find(ele => ele.data.uid === place.pathId);
		const title = path ? path.data.title : 'S/N';
        const family = familyToString(place.family);
        const icon = place.style.color && place.style.size ? 
		  systemIcons(place.style.color, place.style.size, iconType) : systemIcons('', 0, iconType);
        if (!isNaN(place.lat) && !isNaN(place.lng)) {
		  const opacityValue = layerName === title.slice(0, 20) ? 1 : 0.5;
		  const dragable = layerName === title.slice(0, 20) && !readOnly ? true : false;
          if (showMarkerIcons) {
			const placeMarker = L.marker([place.lat, place.lng], { 
			    icon: icon, 
				opacity: opacityValue, 
				draggable: dragable 
			  }).bindTooltip(`<b>${family}</b><br>${place.label}<br>${place.description}`);
            placeMarker.id = variable + '||' + place.elementId;
			const elementLatLng = toLatLng(place.lat, place.lng);
            bounds.extend(elementLatLng);

			const parentLatLng = place.destLat !== null && place.destLng !== null && place.destLat !== place.lat && place.destLng !== place.lng
              ? toLatLng(place.destLat, place.destLng)
              : null;
			if (parentLatLng !== null) {
			  const family = path ? familyToString(path.data.subType) : 'Trayecto';
              const line = L.polyline([elementLatLng, parentLatLng], { 
			      color: path ? path.data.style.color : '#00000080', 
				  weight: path ? path.data.style.weight * 2 : 2, 
				  opacity: path ? opacityValue : 0.25, 
				  dashArray: path ? path.data.style.dashArray : '2, 5',
			    }).bindTooltip(`<b>${family}</b><br>${title}`);
			  line.id = variable + '||' + place.pathId;
			  line.on('click', (event) => {
				const lineData = line.id.split('||');
				if (lineData[0] !== '') {
				  const queryMap = {
					[`var-${lineData[0]}`]: lineData[1],
				  };
				  try {
					locationService.partial(queryMap, true);
				  } catch (error) {
					console.error(error);
				  }
				}
				if (!tableMode) {
				  onSelectionChange(lineData[1]);
				}
			  });
			  markerLines.set(placeMarker, line);
			  allLines.push(line); 
			  bounds.extend(parentLatLng);
			  elementLayer.addLayer(line);
           }
			placeMarker.on('movestart', (event) => {
              originalPosition = event.target.getLatLng();
            });
			placeMarker.on('moveend', (event) => {
			  let mustUpdate = false;
			  const newLatLng = event.target.getLatLng();
			  const line = markerLines.get(placeMarker);
			  allLines.forEach(existingLine => {
				const latLngs = existingLine.getLatLngs();
				const isNotIconLine = line ? line._leaflet_id !== existingLine._leaflet_id : false;
				if (isNotIconLine || !line) {
				  if (latLngs[0] !== null) {
					if (latLngs[0].lat === originalPosition.lat && latLngs[0].lng === originalPosition.lng) {
					  latLngs[0] = newLatLng;
					  mustUpdate = true;
					}
				  }
				  if (!mustUpdate && latLngs[1] !== null) {
					if (latLngs[1].lat === originalPosition.lat && latLngs[1].lng === originalPosition.lng) {
					  latLngs[1] = newLatLng;
					  mustUpdate = true;
					}
				  }
				} else if (line) {
				  const oldLatLngs = line.getLatLngs();
				  line.setLatLngs([newLatLng, oldLatLngs[1]]);
				}
				if (mustUpdate) {
				  existingLine.setLatLngs(latLngs);
				  mustUpdate = false;
				}
			  });
			  if (!readOnly) {
			    onCoordinatesChange([newLatLng.lat.toFixed(7), newLatLng.lng.toFixed(7)], placeMarker.id);
			  }
            });
			placeMarker.on('click', (event) => {
			  const markerData = placeMarker.id.split('||');
			  if (markerData[0] !== '') {
			    const queryMap = {
			      [`var-${markerData[0]}`]: markerData[1],
			    };
				try {
				  locationService.partial(queryMap, true);
				} catch (error) {
				  console.error(error);
				}
			  }
			  if (!tableMode) {
				onSelectionChange(markerData[1]);
			  }
            });
		    if (layerName === 'Seleccion') {
			  placeMarker.on('moveend', function (event) {
				const newCoords = event.target.getLatLng();
				const markerData = placeMarker.id.split('||');
				if (!readOnly) {
				  onCoordinatesChange([newCoords.lat.toFixed(7), newCoords.lng.toFixed(7)], markerData[1]);
				}
			  });
			}
			placeMarker.addTo(map);
			//bounds.extend([place.lat, place.lng]);
			elementLayer.addLayer(placeMarker);
          }
        } else {
          console.error(`Invalid coordinates for place: ${place.label}`);
        }
      }
    });
	elementLayer.addTo(map);
	controlLayer.addOverlay(elementLayer, layerName);
	return bounds;
  };

  const drawPlaces = (places: [], map: L.Map, controlLayer: any, layerName: string, variable: string) => {
	const markerLayer = L.featureGroup();
	const bounds = L.latLngBounds();
    places.forEach(place => {
      if (place.data.elementType == 'emp') {
        const family = familyToString(place.data.subType);
        const icon = place.data.style.color && place.data.style.size ? 
		  systemIcons(place.data.style.color, place.data.style.size,  place.data.elementType) : systemIcons('', 0,  place.data.elementType);
        if (!isNaN(place.data.coordinates[0]) && !isNaN(place.data.coordinates[1])) {
		  const opacityValue = 0.75;
		  const dragable = false;
		  const placeMarker = L.marker(place.data.coordinates, { 
			icon: icon, 
			opacity: opacityValue, 
			draggable: dragable 
		  }).bindTooltip(`<b>Emplazamiento - </>${family}</b><br>${place.data.idx}<br>${place.data.title}`);
          placeMarker.id = variable + '||' + place.data.uid;
		  placeMarker.on('click', (event) => {
			const markerData = placeMarker.id.split('||');
			if (markerData[0] !== '') {
			  const queryMap = {
			    [`var-${markerData[0]}`]: markerData[1],
			  };
			  try {
				locationService.partial(queryMap, true);
			  } catch (error) {
				console.error(error);
			  }
			}
			if (!tableMode) {
			  onSelectionChange(markerData[1]);
			}
		  });
		  markerLayer.addLayer(placeMarker);
		  bounds.extend(place.data.coordinates);
        } else {
          console.log(`Invalid coordinates for place: ${place.data.title}`);
        }
      }
    });
	markerLayer.addTo(map);
    controlLayer.addOverlay(markerLayer, layerName);
	return bounds;
  };

  const drawPaths = (routes, items, map, controlLayer, layerName: string, variable: string) => {
	const routeLayer = L.featureGroup();
	const bounds = L.latLngBounds();
    routes.forEach(route => {
      const family = familyToString(route.data.subType);
	  const coordinates = route.data.coordinates ? route.data.coordinates : [];
      if (coordinates.length >= 2) {
        for (let i = 0; i < coordinates.length - 1; i++) {
          const start = coordinates[i];
          const end = coordinates[i + 1];
          if (!isNaN(start[0]) && !isNaN(start[1]) && !isNaN(end[0]) && !isNaN(end[1])) {
            const line = L.polyline([start, end], {
              color: route.data.style.color,
              weight: route.data.style.weight * 2,
              opacity: 0.8,
              dashArray: route.data.style.dashArray,
            }).bindTooltip(`${family}<br>${route.data.title}`);
            line.id = variable + '||' + route.data.uid;
			line.on('click', (event) => {
			  const lineData = line.id.split('||');
			  if (lineData[0] !== '') {
				const queryMap = {
				  [`var-${lineData[0]}`]: lineData[1],
				};
				try {
				  locationService.partial(queryMap, true);
				} catch (error) {
				  console.error(error);
				}
			  }
			  if (!tableMode) {
				onSelectionChange(lineData[1]);
			  }
			});
			routeLayer.addLayer(line);
			bounds.extend(start);
            bounds.extend(end);
          } else {
            console.error(`Invalid coordinates for route segment: ${start} to ${end}`);
          }
        }
      } else if (route.data.destination !== '' && route.data.origin !== '') {
		const origin = items.find(item => item.data.idx === route.data.origin);
		const destination = items.find(item => item.data.idx === route.data.destination);
		if (origin && destination) {
		  const start = origin.data.coordinates;
          const end = destination.data.coordinates;
          if (!isNaN(start[0]) && !isNaN(start[1]) && !isNaN(end[0]) && !isNaN(end[1])) {
            const line = L.polyline([start, end], {
              color: route.data.style.color,
              weight: route.data.style.weight * 2,
              opacity: 0.8,
              dashArray: route.data.style.dashArray,
            }).bindTooltip(`<b>${family}</b><br>${route.data.title}`);
            line.id = variable + '||' + route.data.uid; 
			line.on('click', (event) => {
			  const lineData = line.id.split('||');
			  if (lineData[0] !== '') {
				const queryMap = {
				  [`var-${lineData[0]}`]: lineData[1],
				};
				try {
				  locationService.partial(queryMap, true);
				} catch (error) {
				  console.error(error);
				}
			  }
			  if (!tableMode) {
				onSelectionChange(lineData[1]);
			  }
			});
			routeLayer.addLayer(line);
			bounds.extend(start);
            bounds.extend(end);
          } else {
            console.error(`Invalid coordinates for route segment: ${start} to ${end}`);
          }
		}
	  }
    });
    routeLayer.addTo(map);
    controlLayer.addOverlay(routeLayer, layerName);
	return bounds;
  };

  const redrawPolyline = () => {
    currentLayer.clearLayers(); // Limpia todas las capas
    currentNodes.forEach((current) => {
      for (let i = 0; i < current.coordinates.length - 1; i++) {
        const start = current.coordinates[i];
        const end = current.coordinates[i + 1];
        if (!isNaN(start[0]) && !isNaN(start[1]) && !isNaN(end[0]) && !isNaN(end[1])) {
          const line = L.polyline([start, end], {
            color: current.style.color,
            weight: current.style.weight * 4,
          });
          currentLayer.addLayer(line);
        }
      }
    });
  };

  let hasCurrentNode = false;
  if (currentNodes[0]) {
    if (currentNodes[0].uid) {
      hasCurrentNode = true;
	}
  }

  useEffect(() => {
	if (!leaf) {
	  leaf = L.map(mapRef.current!, mapOptions);
      L.tileLayer(arcgisUrl, {
		attribution: attribution,
		minZoom: 3,
		maxZoom: 19,
	  }).addTo(leaf);

	  currentLayer = L.featureGroup();
	  pointLayer = L.featureGroup();
	  control = L.control.layers(null, null, { collapsed:false }).addTo(leaf);

	  if (!isKmzAddMode && !hasCurrentNode) {
		leaf.on('zoomend', (e) => {
		  if (renderCount >= 2) {
			const actualZoom = leaf.getZoom();
			if (!zoom || zoom !== actualZoom) {
		      const actualCenter = leaf.getCenter();
			  onPosChange(actualCenter, actualZoom);
			}
		  }
		});

		leaf.on('moveend', (e) => {
		  if (renderCount >= 2) {
		    const actualCenter = leaf.getCenter();
			if (!center || (center.lat !== actualCenter.lat || center.lng !== actualCenter.lng)) {
			  const actualZoom = leaf.getZoom();
			  onPosChange(actualCenter, actualZoom);
			}
		  }
		});
	  }

	  if (isKmzAddMode) {
	    isKmzAddMode.reader.onload = (event) => {
		  const base64KMZData = isKmzAddMode.reader.result;
		  const byteCharacters = atob(base64KMZData.split(',')[1]);
		  const byteNumbers = new Array(byteCharacters.length);
		  for (let i = 0; i < byteCharacters.length; i++) {
		    byteNumbers[i] = byteCharacters.charCodeAt(i);
		  }
		  const byteArray = new Uint8Array(byteNumbers);
		  const blob = new Blob([byteArray], { type: 'application/vnd.google-earth.kmz' });
		  const blobUrl = URL.createObjectURL(blob);

	      kmz = L.kmzLayer().addTo(leaf);
	      kmz.on('load', function (e) {
			const layerGroup = e.layer;
			const geoJSONData = layerGroup.toGeoJSON();
			const elementArray = geoJSONData.features;
			onImportData(elementArray, isKmzAddMode.file);
	      });
		  kmz.load(blobUrl);
		}
	  }

	  const isPlace = hasCurrentNode && (currentNodes[0].elementType === 'emp' || currentNodes[0].elementType === 'element' || 
		currentNodes[0].elementType === 'splicer' || currentNodes[0].elementType === 'splitter') ? true : false;
	  const reference = hasCurrentNode && currentNodes[0].title ? currentNodes[0].title.slice(0, 20) : 'Seleccion actual';
	  const coordsX = geoVariables[2] && isPlace && currentNodes[0].coordinates.length > 0 ? currentNodes[0].coordinates[0] : actualX;
	  const coordsY = geoVariables[3] && isPlace && currentNodes[0].coordinates.length > 0 ? currentNodes[0].coordinates[1] : actualY;
	  const currentPathId = hasCurrentNode ? currentNodes[0].pathId : 'No pathId';
	  const currentUid = hasCurrentNode ? currentNodes[0].uid : 'No nodeId';
	  const currentDestination = hasCurrentNode && !isPlace ? currentNodes[0].destination : 'No toId';
	  const currentOrigin = hasCurrentNode && !isPlace ? currentNodes[0].origin : 'No fromId';

	  let currentPathRelations = markers.length > 0 ? markers.filter(value => value.pathId === currentPathId) : [];
	  currentPathRelations = hasCurrentNode ? currentPathRelations.filter(value => value.uid !== currentPathId) : currentPathRelations;
	  const restOfElementsRelations = markers.length > 0 ? markers.filter(value => value.pathId !== currentPathId) : [];

	  if (items) {
		  const currentPaths = items.length > 0 ? 
			items.filter(value => value.data.uid === currentPathId && value.data.elementType === 'path') : [];
		  const restOfPaths = items.length > 0 ? 
			items.filter(value => value.data.uid !== currentPathId && value.data.elementType === 'path' && !value.data.wasChange) : [];
		  const newPaths = items.length > 0 ? 
			items.filter(value => value.data.uid !== currentPathId && value.data.elementType === 'path' && value.data.wasChange) : [];

		  const currentPathElements = items.length > 0 ? 
			items.filter(value => value.data.pathId === currentPathId && value.data.elementType === 'element') : [];
		  const restOfElements = items.length > 0 ? 
			items.filter(value => value.data.uid !== currentUid && value.data.elementType === 'element' && !value.data.wasChange) : [];
		  const newElements = items.length > 0 ? 
			items.filter(value => value.data.uid !== currentUid && value.data.elementType === 'element' && value.data.wasChange) : [];

		  const currentEmps = items.length > 0 ? 
			items.filter(value => value.data.elementType === 'emp' && !value.data.wasChange && value.data.uid !== currentUid &&
			  (value.data.uid === currentDestination || value.data.uid === currentOrigin)) : [];
		  const restOfEmps = items.length > 0 ? 
			items.filter(value => value.data.elementType === 'emp' && !value.data.wasChange && value.data.uid !== currentUid &&
			  value.data.uid !== currentDestination && value.data.uid !== currentOrigin) : [];
		  const newEmps = items.length > 0 ? 
			items.filter(value => value.data.elementType === 'emp' && value.data.wasChange && value.data.uid !== currentUid &&
			  value.data.uid !== currentDestination && value.data.uid !== currentOrigin) : [];
			  
		  const currentSpans = items.length > 0 ? 
			items.filter(value => value.data.elementType === 'span' && value.data.uid === currentPathId && !value.data.wasChange) : [];
		  const restofSpans = items.length > 0 ? 
			items.filter(value => value.data.elementType === 'span' && value.data.uid !== currentPathId && !value.data.wasChange) : [];
		  const newSpans = items.length > 0 ? 
			items.filter(value => value.data.elementType === 'span' && value.data.uid !== currentPathId && value.data.wasChange) : [];

		  const currentLinks = items.length > 0 ? 
			items.filter(value => value.data.elementType === 'link' && value.data.uid === currentPathId && !value.data.wasChange) : [];
		  const restofLinks = items.length > 0 ? 
			items.filter(value => value.data.elementType === 'link' && value.data.uid !== currentPathId && !value.data.wasChange) : [];
		  const newLinks = items.length > 0 ? 
			items.filter(value => value.data.elementType === 'link' && value.data.uid !== currentPathId && value.data.wasChange) : [];

		  const currentSegments = items.length > 0 ? 
			items.filter(value => value.data.elementType === 'segment' && value.data.uid === currentPathId && !value.data.wasChange) : [];
		  const restofSegments = items.length > 0 ? 
			items.filter(value => value.data.elementType === 'segment' && value.data.uid !== currentPathId && !value.data.wasChange) : [];
		  const newSegments = items.length > 0 ? 
			items.filter(value => value.data.elementType === 'segment' && value.data.uid !== currentPathId && value.data.wasChange) : [];

		  const currentUnions = items.length > 0 ?
			items.filter(value => (value.data.elementType === 'splicer' || value.data.elementType === 'splitter') && value.data.uid !== currentUid &&
			  value.data.uid === currentPathId && !value.data.wasChange) : [];
		  const restofUnions = items.length > 0 ?
			items.filter(value => (value.data.elementType === 'splicer' || value.data.elementType === 'splitter') && value.data.uid !== currentUid &&
			  value.data.uid !== currentPathId && !value.data.wasChange) : [];
		  const newUnions = items.length > 0 ?
			items.filter(value => (value.data.elementType === 'splicer' || value.data.elementType === 'splitter') && value.data.uid !== currentUid &&
			  value.data.uid === currentPathId && value.data.wasChange) : [];

	      selectionBounds[0] = currentPathRelations.length > 0 ? 
		    drawMarkers(currentPathRelations, items, leaf, control, reference, geoVariables[4]) : null;
		  selectionBounds[1] = restOfElementsRelations.length > 0 ? 
		    drawMarkers(restOfElementsRelations, items, leaf, control, 'Elementos', geoVariables[4]) : null;

		  selectionBounds[2] = currentPaths.length > 0 ? drawPaths(currentPaths, items, leaf, control, reference, geoVariables[4]) : null;
		  objectBounds[1] = (restOfPaths.length > 0 || newPaths.length > 0) ? 
			drawPaths([...restOfPaths, ...newPaths], items, leaf, control, 'Trayectos', geoVariables[4]) : null;				

		  selectionBounds[3] = currentEmps.length > 0 ? drawPlaces(currentEmps, leaf, control, reference, geoVariables[4]) : null;
		  objectBounds[2] = (restOfEmps.length > 0 || newEmps.length > 0) ? 
			drawPlaces([...restOfEmps, ...newEmps], leaf, control, 'Emplazamientos', geoVariables[4]) : null;

		  selectionBounds[4] = currentSpans.length > 0 ? drawPaths(currentSpans, items, leaf, control, reference, geoVariables[4]) : null;
		  objectBounds[3] = (restofSpans.length > 0 || newSpans.length > 0) ? 
			drawPaths([...restofSpans, ...newSpans], items, leaf, control, 'Tramos', geoVariables[4]) : null;	

		  selectionBounds[5] = currentLinks.length > 0 ? drawPaths(currentLinks, items, leaf, control, reference, geoVariables[4]) : null;
		  objectBounds[4] = (restofLinks.length > 0 || newLinks.length > 0) ? 
			drawPaths([...restofLinks, ...newLinks], items, leaf, control, 'Enlaces', geoVariables[4]) : null;	
			
		  selectionBounds[6] = currentSegments.length > 0 ? drawPaths(currentSegments, items, leaf, control, reference, geoVariables[4]) : null;
		  objectBounds[5] = (restofSegments.length > 0 || newSegments.length > 0) ? 
			drawPaths([...restofSegments, ...newSegments], items, leaf, control, 'Segmentos', geoVariables[4]) : null;	

		  selectionBounds[7] = currentUnions.length > 0 ? drawPlaces(currentUnions, leaf, control, reference, geoVariables[4]) : null;
		  objectBounds[6] = (restofUnions.length > 0 || newUnions.length > 0) ? 
			drawPaths([...restofUnions, ...newUnions], items, leaf, control, 'Eventos', geoVariables[4]) : null;	
	  } else {
	    const currentItem = [];
		const newItem = {
		  data: currentNodes[0],
		  id: 0,
		}
		currentItem.push(newItem);
		selectionBounds[0] = currentPathRelations.length > 0 ? drawMarkers(currentPathRelations, currentItem, leaf, control, reference) : null;
		selectionBounds[1] = restOfElementsRelations.length > 0 ? drawMarkers(restOfElementsRelations, items, leaf, control, 'Elementos') : null;
	  }

	  if (hasCurrentNode && currentNodes[0].uid) {
	    currentNodes.forEach((current) => {
		  if (current.uid !== null && current.coordinates.length > 0) {
		    if (isPlace) {
			  const family = familyToString(current.subType);
			  const iconType = isPlace ? 'current' : current.elementType;
			  const icon = current.style.color && current.style.size ? 
			    systemIcons(current.style.color, current.style.size * 1.5, iconType) : systemIcons('', 32, iconType);
		      const marker = L.marker(toLatLng(current.coordinates[0], current.coordinates[1]), {
			    icon: icon, 
			    draggable: !readOnly 
			  }).bindTooltip(`<b>${family}</b><br>${current.elementId}<br>${current.title}`);
			  marker.id = geoVariables[4] + '||' + current.uid;
			  if (!readOnly) {
			    marker.on('moveend', function (e) {
				  const newCoords = e.target.getLatLng();
				  onCoordinatesChange([newCoords.lat.toFixed(7), newCoords.lng.toFixed(7)], current.elementId);
			    });
			  }
			  currentLayer.addLayer(marker);
		    } else {
			  /*for (let i = 0; i < current.coordinates.length - 1; i++) {
			    const family = familyToString(current.subType);
			    const start = current.coordinates[i];
			    const end = current.coordinates[i + 1];
			    if (!isNaN(start[0]) && !isNaN(start[1]) && !isNaN(end[0]) && !isNaN(end[1])) {
				  const line = L.polyline([start, end], {
				    color: current.style.color,
				    weight: current.style.weight * 4,
				  }).bindTooltip(`${family}<br>${current.title}`);
				  line.id = current.uid;
				  currentLayer.addLayer(line);
			    } else {
				  console.error(`Invalid coordinates for route segment: ${start} to ${end}`);
			    }
			  }*/
				for (let i = 0; i < current.coordinates.length; i++) {
				  const point = current.coordinates[i];
				  const family = familyToString(current.subType);
				  const icon = systemIcons('red', 16, 'current');
				  if (!isNaN(point[0]) && !isNaN(point[1])) {
					const pointMarker = L.marker(point, {
					  icon: icon, 
					  draggable: !readOnly,
					}).bindTooltip('Arrastre para modificar la trayectoria'); 
					pointMarker.id = current.uid + '||' + String(i);
					if (!readOnly) {
					  pointMarker.on('moveend', function (e) {
					    const newLatLng = e.target.getLatLng();
					    const pointData = pointMarker.id.split('||');
					    const thisIndex = Number(pointData[1]);
					    console.log(thisIndex, newLatLng);
					    current.coordinates[thisIndex] = [newLatLng.lat, newLatLng.lng];
					    redrawPolyline();
					    onLineChanges(current.coordinates, pointData[0]);
					  });
					}
					pointLayer.addLayer(pointMarker);
				  } else {
					console.error(`Invalid coordinate at index ${i}: ${point}`);
				  }
				}
				for (let i = 0; i < current.coordinates.length - 1; i++) {
				  const start = current.coordinates[i];
				  const end = current.coordinates[i + 1];
				  const family = familyToString(current.subType);
				  if (!isNaN(start[0]) && !isNaN(start[1]) && !isNaN(end[0]) && !isNaN(end[1])) {
					const line = L.polyline([start, end], {
					  color: current.style.color,
					  weight: current.style.weight * 4,
					}).bindTooltip(`${family}<br>${current.title}`);
					line.id = current.uid;
					currentLayer.addLayer(line);
				  } else {
					console.error(`Invalid coordinates for route segment: ${start} to ${end}`);
				  }
				}
			}
		  }
		});
		pointLayer.addTo(leaf);
		currentLayer.addTo(leaf);
		objectBounds[0] = currentLayer.getBounds();
	  }

	  const selectionFiltered = selectionBounds.filter(item => item && Object.keys(item).length > 0);
	  const boundsFiltered = objectBounds.filter(item => item && Object.keys(item).length > 0);
	  const largestBounds = hasCurrentNode && selectionBounds[0] ? 
	    findLargestBounds(selectionFiltered) : findLargestBounds([...selectionFiltered, ...boundsFiltered]);
	  if (hasCurrentNode && currentNodes[0].uid && objectBounds[0]._northEast && objectBounds[0]._southWest) {
	    leaf.fitBounds(objectBounds[0]);
	  } else if (hasPosition && renderCount < 3) {
	    leaf.flyTo(toLatLng(actualY, actualX), actualZ);
	  } else if (zoom !== undefined && center !== undefined) {
	    leaf.setView(center, zoom);
	  } else if (largestBounds) {
	    leaf.fitBounds(largestBounds);
	  }
    }

    return () => {
	  leaf?.remove();
    };
  }, [currentNodes, items, markers, isKmzAddMode]);

  return (
    <div ref={mapRef} style={{ width: '100%', height: '100%' }} />
  );
};
