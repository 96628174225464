import { SelectableValue } from '@grafana/data';

export interface HelpPanelOptions {
  uid: string;
  title: string;
  technicalDetails: string;
  updated_at: string;
  created_at: string;
  user: string;
  version: string;
  reference: string;
  api: string;
  imageServer: string;
  maxImageSize: string;
  maxFileSize: string;
  mapSource: string;
  showOnlyInUse: boolean;
  dashboardIdVariable: string;
  uidVariable: string;
  hashVariable: string;
  alarmIdVariable: string;
  siteDocIdVariable: string;
  assetIdVariable: string;
  typeVariable: string;
  variableToTrack: string;
  viewerMode: boolean;
  showTitle: boolean;
  showTableMode: boolean;
  showSelector: boolean;
  pluginTitle: string;
  successMessage: string;
  editorCanAdmin: boolean;
  canAddNotes: boolean;
  enablePreview: boolean;
  previewOnly: boolean;
  saniticeDoc: boolean;
  showTitle: boolean;
  showPlaceSelector: boolean;
  error4: string;
}

export const valueToSubtype: SelectableValue[] = [
  { label: 'Caso de uso', description: 'Como utilizar una funcion especifica de NetMonitor', value: 'useCase', family: 'help', showToViewer: true },
  { label: 'Guia', description: 'Ayuda sobre una funcionalidad de la plataforma', value: 'function', family: 'help', showToViewer: true },
  { label: 'Panel', description: 'Descripción sobre el uso de un panel especifico', value: 'panel', family: 'help', showToViewer: true },
  { label: 'Emplazamiento', description: 'Documentación referida a la instalación y operación de un emplazamiento', value: 'emp', family: 'sitedoc', showToViewer: true },
  { label: 'Activo', description: 'Documentación referida a la instalación y operación de un activo en particular', value: 'asset', family: 'sitedoc', showToViewer: true },
  { label: 'Plugin', description: 'Guia de configuración para plugins de NM', value: 'plugin', family: 'internal', showToViewer: false },
  { label: 'Caracteristica', description: 'Ayuda sobre el funcionamiento o metodo de calculo de un feature de NM', value: 'feature', family: 'internal', showToViewer: false },
  { label: 'Alarma', description: 'Nota asociada a un ID de Alarma', value: 'alarm', family: 'notes', showToViewer: true },
  { label: 'Alarma', description: 'Nota asociada a un ID de Alerta', value: 'alert', family: 'notes', showToViewer: true },
  { label: 'Panel', description: 'Nota asociada a un Panel especifico', value: 'dash', family: 'notes', showToViewer: true },
];

export const valueToType: SelectableValue[] = [
  { label: 'Ayuda', description: 'Documento con información de ayuda', value: 'help' , order: 0, showToViewer: true},
  { label: 'Nota', description: 'Nota contextual', value: 'notes', order: 1, showToViewer: true },
  { label: 'Site Doc', description: 'Documentación de Sitio', value: 'sitedoc', order: 2, showToViewer: true },
  { label: 'Interno', description: 'Documento con información interna', value: 'internal', order: 1, showToViewer: false },
];

export const valueToStatus: SelectableValue[] = [
  { label: 'Eliminada', description: 'Documento que fue eliminado', value: 'delete', showToViewer: false, showToEditor: false },
  { label: 'En Uso', description: 'Documento en uso', value: 'in_use', showToViewer: true, showToEditor: true  },
  { label: 'Sin Uso', description: 'Documento que no es utilizado', value: 'disable', showToViewer: false, showToEditor: true  },
  { label: 'Obsoleto', description: 'Documento obsoleto o reemplazado', value: 'deprecated', showToViewer: false, showToEditor: true  },
  { label: 'En Revisión', description: 'Documento para revisión por pares', value: 'to_review', showToViewer: false, showToEditor: true  },
  { label: 'En Redaccion', description: 'Documento que aun esta siendo elaborado', value: 'under_construction', showToViewer: false, showToEditor: true  },
];

export const valueToCategory: SelectableValue[] = [
  { label: 'Usuario General', description: 'Documento con información general de la plataforma', value: 'viewer' },
  { label: 'Supervidor', description: 'documento relacionado con funciones visibles para supervisores', value: 'editor' },
  { label: 'Soporte L1', description: 'Documento para personal de soporte de 1er nivel', value: 'l1Support' },
  { label: 'Soporte L2', description: 'Documento para personal de soporte de 2do nivel', value: 'l2Support' },
  { label: 'Soporte L3', description: 'Documento para personal de soporte de 3er nivel', value: 'l3Support' },
  { label: 'Mantenimiento', description: 'Documento destinado a Operación y Manteniemiento de una emplezamiento', value: 'oym' },
];