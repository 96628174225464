import { intersectionWith, isEqual } from 'lodash';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import moment from 'moment';

import { ConfirmModal, HorizontalGroup, IconButton } from '@grafana/ui';
import { AppEvents } from '@grafana/data';
import { contextSrv } from 'app/core/services/context_srv';
import { prepareItems } from 'app/features/alerting/unified/utils/dynamicTable';
import { EmptyArea } from 'app/features/alerting/unified/components/EmptyArea';
import { L1OyMData, L1OyMStyle } from './L1OyMManager';
import { Text } from './Tags';
import { valueToType, valueToSubtype, valueToStatus } from './types';
import { DynamicTable, DynamicMap, DynamicTableColumnProps, DynamicTableItemProps } from './L1OyMDynamicTable';

export interface l1OyMTableProps {
  renderCount: number;
  isAdmin: boolean;
  onSave: (elements: L1OyMData[]) => void;
  onCancel: () => void;
  filters?: { title?: string; elementType?: string; subType?: string;  place?: string, hasChange: boolean, version: number };
  isDark:boolean;
  width: number;
  height: number;
  elements: L1OyMData[];
  places: [];
  types: [];
  sites: [];
  pluginVariables: [];
  isKmzAddMode: any;
}

type RouteTableColumnProps = DynamicTableColumnProps<L1OyMData>;
type RouteTableItemProps = DynamicTableItemProps<L1OyMData>;

export const getFilteredElements = (
  elements: L1OyMData[],
  isElement?: boolean,
  title?: string,
  elementType?: string,
  subType?: string,
  place?: string,
  hasChange?: boolean,
  version?: number,
) => {
  let elementsFiltered:[] = [];
  const elementsToFilter = elements.filter(ele => ele.status !== 'delete');
  elementsToFilter.forEach((ele, index) => {
    const element = isElement ? ele : ele.data;
	let mustFilter = false;
	if (title) {
	  mustFilter = element.title.toLowerCase().includes(title.toLowerCase()) ? mustFilter : true;
    }
	if (elementType !== undefined) {
	  if (elementType === 'path' && subType) {
	    mustFilter = element.pathId === subType ? mustFilter : true;
	  } else if (elementType === 'link' && subType) {
	    mustFilter = element.linkId === subType ? mustFilter : true;
	  } else if (subType) {
	    mustFilter = element.elementType === elementType && element.subType === subType ? mustFilter : true;
	  }
	} else if (subType) {
	  mustFilter = element.subType.toLowerCase() === subType.toLowerCase() ? mustFilter : true;
    } 
	if (place !== undefined && element.origin !== place && element.destination !== place) {
	  mustFilter = true;
	}
	if (hasChange !== undefined && element.wasChange !== hasChange) {
	  mustFilter = true;
	}
	if (version !== undefined  && element.version !== version) {
	  mustFilter = true;
	}
	if (!mustFilter) {
	  elementsFiltered.push(element);
	}
  });

  const sortedElements = elementsFiltered.sort((a, b) => {
    if (a.elementType < b.elementType) return 1;
    if (a.elementType > b.elementType) return -1;

    if (a.subType < b.subType) return 1;
    if (a.subType > b.subType) return -1;

    if (a.title < b.title) return 1;
    if (a.title > b.title) return -1;
  })
  if (isElement) {
    return sortedElements;
  } else {
	const sortedItems = sortedElements.map(item => ({
	  data: item,
	  id: item.id
	}));
	return sortedItems;
  }
};

export const cleanElements = (items: L1OyMData[]): L1OyMData[] => {
  const cleannerElements: L1OyMData[] = [];
  for (let i = 0; i < items.length; i++) {
	const item = items[i].data;
	let elementData = item;
	cleannerElements.push(elementData);
  }
  return cleannerElements;
};

export const generarID = () => {
  const caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let id = '';
  for (let i = 0; i < 10; i++) {
    id += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
  }
  return id;
}

export const updateElement = (actualElements: L1OyMData[], item: l1OyM): L1OyMData[] => {
  const newElements = [...actualElements];
  const editIndex = newElements.findIndex((element) => element.id === item.id);
  const userName = contextSrv.user.name;
  item.updated = '';
  item.version = item.version + 1;
  item.user = userName;
  if (editIndex >= 0) {
    newElements[editIndex] = {
      ...newElements[editIndex],
      ...item,
    };
  } else {
    newElements.push(item);
  }
  return newElements;
};

export const getStyle = (styleId: string, elementType: string, importId: string, title: string): L1OyMStyle[] => {
  let style = generarID();
  if (styleId && styleId.length > 0) {
    style = styleId;
  }
  const colors = [
    "#F17171",
    "#71E200",
    "#A2ADB8",
    "#7100FF",
    "#FFA071",
	"#A20471",
    "#FF71B7", 
    "#7EA9ff",
    "#FF0071",
    "#B380FF"
  ];
  const dashPatterns = [
    '2, 1',
    '1, 2',
    '2, 2',
  ];

  const hash = Array.from(style).reduce((acc, char) => acc + char.charCodeAt(0), 0);
  const colorId = (hash % 30) + 1;
  const fillColor = colors[hash % colors.length];
  const dashArray = dashPatterns[hash % dashPatterns.length];
  const pathId = elementType === 'span' ? generarID() : '';
  
  const elementStyle = elementType === 'span' ?
    { id: style, color: fillColor, dashArray: dashArray, pathId: pathId, title: title, size: 24, weight: 1, icon: '', importId: importId } :
	{ id: style, color: fillColor, dashArray: dashArray, size: 24, weight: 1, icon: '', importId: importId };

  return elementStyle
}

export const updateImportElements = (actualElements: L1OyMData[], newElements: L1OyMData[]): L1OyMData[] => {
  const existingElements: L1OyMData[] = [...actualElements];
  const empStyles: [] = [];
  const spanStyles: [] = [];
  let wasConfirmEmp = false;
  let mustUpdateEmp = false;
  let wasConfirm = false;
  let mustUpdate = false;
  for (let i = 0; i < newElements.length; i++) {
	let item = newElements[i];
    if (item.elementType === 'emp' && item.coordinates.length > 1 && item.title) {
	  const editIndex = existingElements.findIndex((element) => 
	    element.elementType === item.elementType && 
	    element.subType === item.subType && 
	    element.title === item.title 
      );
      if (editIndex >= 0) {
		const hasSameCoords = _.isEqual(item.coordinates, existingElements[editIndex].coordinates);
		if (!hasSameCoords) {
		  if (!wasConfirmEmp) {
		    mustUpdateEmp = window.confirm(`¿Sobrescribir datos de emplazamientos existentes?`);
		    wasConfirmEmp = true;
		  }
		  if (mustUpdateEmp) {
		    item.updated = '';
	        item.wasChange = true;
            item.version = existingElements[editIndex].version + 1;
		    item.style = existingElements[editIndex].style;
			item.uid = existingElements[editIndex].uid;
		    item.idx = existingElements[editIndex].idx;
            existingElements[editIndex] = {
              ...existingElements[editIndex],
              ...item,
            };
		  }
		}
	  } else {
	    let empStyle = empStyles.find((style) => style.id === item.style.id);
		if (!empStyle) {
		  const styleColors = getStyle(item.style.id, item.elementType, item.importId);
		  empStyles.push(styleColors);
		  empStyle = styleColors;
		}
		item.style = empStyle;
		item.updated = '';
	    item.wasChange = true;
        item.version = item.version + 1;
        existingElements.push(item);
	  }
	} else if (item.elementType === 'span' && item.coordinates.length > 1 && item.title) {
	  const similarSpans = existingElements.filter((element) => 
	    element.elementType === item.elementType && 
	    element.subType === item.subType && 
	    element.title === item.title 
      );
      if (similarSpans.length >= 0) {
	    const hasSameCoords = similarSpans.find(span => _.isEqual(item.coordinates, span.coordinates));
		if (!hasSameCoords) {
		  if (!wasConfirm) {
		    mustUpdate = window.confirm(`¿Importar multiples tramos con la misma Designación?`);
		    wasConfirm = true;
		  }
		  if (mustUpdate) {
			let spanStyle = spanStyles.find((style) => style.id === item.style.id);
			if (!spanStyle) {
			  const styleColors = getStyle(item.style.id, item.elementType, item.importId, item.title);
			  spanStyles.push(styleColors);
			  spanStyle = styleColors;
			}
			item.style = {
			  color: spanStyle.color,
			  size: spanStyle.size,
			  weight: spanStyle.weight,
			  icon: spanStyle.icon,
			  dashArray: spanStyle.dashArray,
			  id: spanStyle.id
			};
			item.pathId = spanStyle.pathId;
			item.capacity = 1;
		    item.updated = '';
	        item.wasChange = true;
            item.version = item.version + 1;
            existingElements.push(item);
	      }
	    }
	  } else {
	    let spanStyle = spanStyles.find((style) => style.id === item.style.id);
		if (!spanStyle) {
		  const styleColors = getStyle(item.style.id, item.elementType, item.importId, item.title);
		  console.log(styleColors);
		  spanStyles.push(styleColors);
		  spanStyle = styleColors;
		}
	    item.style = {
		  color: spanStyle.color,
		  size: spanStyle.size,
		  weight: spanStyle.weight,
		  icon: spanStyle.icon,
		  dashArray: spanStyle.dashArray,
		  id: spanStyle.id
		};
		item.pathId = spanStyle.pathId;
		item.capacity = 1;
		item.updated = '';
	    item.wasChange = true;
        item.version = item.version + 1;
        existingElements.push(item);
	  }
	}
  }
  if (spanStyles.length > 0) {
    const createPaths = window.confirm(`¿Desea crear Trayectos para los tramos importados?`);
	if (createPaths) {
	  const d = Date.now();
	  const nextId = existingElements.length;
	  for (let i = 0; i < spanStyles.length; i++) {
		const newPath = {
		  id: i + nextId,
		  uid: spanStyles[i].pathId,
		  idx: 'PATH_' + spanStyles[i].pathId.toUpperCase(),
		  title: 'Trayecto ' + spanStyles[i].title,
		  style: { 
			color: spanStyles[i].color, size: spanStyles[i].size, weight: spanStyles[i].weight, 
			icon: spanStyles[i].icon, dashArray: spanStyles[i].dashArray, id: spanStyles[i].id 
		  },
		  importId: spanStyles[i].importId,
		  state: true,
		  created: moment(d).format('DD/MM/YYYY HH:mm:ss'),
		  updated: '',
		  user: 'Import Process',
		  subType: 'path',
		  elementType: 'path',
		  elementId: '',
		  siteId: '',
		  pathId: spanStyles[i].pathId,
		  spanId: '',
		  sectionType: '',
		  status: 'in_use',
		  installationDate: moment(d).format('DD/MM/YYYY HH:mm:ss'),
		  installationNotes: '',
		  technician: '',
		  lastInspection: moment(d).format('DD/MM/YYYY HH:mm:ss'),
		  nextMaintenance: moment(d).format('DD/MM/YYYY HH:mm:ss'),
		  owner: '',
		  technicalDetails: '',
		  origin: '',
		  destination: '',
		  coordinates: [],
		  address: '',
		  elevation: 0,
		  parentsIds: [],
		  relationIds: [],
		  relationDistances: [],
		  relationCapacities: [],
		  sublinkIds: [],
		  sublinkBuffer: [],
		  sublinkColor: [],
		  sublinkAttenuation: [],
		  sublinkDistance: [],
		  segmentId: '',
		  pathEventId: '',
		  capacity: 1,
		  fiberIds: [],
		  fiberAttenuation: [],
		  lastInspector: '',
		  inspectionReport: '',
		  inspectionNotes: '',
		  version: 1,
		  wasChange: true,
		};
		existingElements.push(newPath);
	  }
	}
  }
  return existingElements;
};

export const saveImportData = async (newElements: [], nextId: number, file: any): L1OyMData[] => {
  const newElementsConfigured: L1OyMData[] = [];
  const userName = contextSrv.user.name;
  const d = Date.now();
  const date = moment(d).format('DD/MM/YYYY HH:mm:ss');
  const importId = `file:${file.name}[${file.size}]${file.lastModified}`
  if (newElements) {
    for (let i = 0; i < newElements.length; i++) {
	  const uidx = generarID();
	  let newElement = {
		id: i + nextId,
		idx: 'EMP_' + uidx.toUpperCase(),
		uid: uidx,
		title: newElements[i].properties.name,
		elementId: 'emp_' + uidx,
		style: {color: '#000000', size: 24, weight: 2, icon: '', dashArray: '', id: newElements[i].properties.styleHash},
		importId: importId,
		siteId: '',
		pathId: '',
		linkId: '',
		status: 'in_use',
		state: true,
		installationDate: '',
		installationNotes: '',
		technician: '',
		lastInspection: '',
		nextMaintenance: '',
		owner: '',
		elementType: 'emp',
		subType: 'emp',
		technicalDetails: '',
		origin: '',
		destination: '',
		coordinates: [],
		address: '',
		elevation: 0,
		parentsIds: [],
		relationIds: [],
		relationDistances: [],
		relationCapacities: [],
		sublinkIds: [],
	    sublinkBuffer: [],
	    sublinkColor: [],
		sublinkAttenuation: [],
		sublinkDistance: [],
		spanId: '',
		segmentId: '',
		pathEventId: '',
		capacity: 0,
		fiberIds: [],
		fiberAttenuation: [],
		lastInspector: '',
		inspectionReport: '',
		inspectionNotes: '',
		inspectionMediaData: '',
		user: userName,
		created: date,
		updated: date,
		version: 0,
		wasChange: false,
	  };
	  if (newElements[i].geometry.type === 'Point') {
		newElement.coordinates = [newElements[i].geometry.coordinates[1], newElements[i].geometry.coordinates[0]];
		newElement.elevation = newElements[i].geometry.coordinates[2];
	  } else if (newElements[i].geometry.type === 'LineString') {
		newElement.idx = 'SPAN_' + uidx.toUpperCase(),
		newElement.elementId = uidx,
		newElement.spanId = uidx,
		newElement.elementType = 'span';
		newElement.subType = 'underground_span';
		const coords = newElements[i].geometry.coordinates;
		const newCoords = [];
		for (let i = 0; i < coords.length - 1; i++) {
		  const point = coords[i];
		  newCoords.push([point[1], point[0]]);
		}
		newElement.coordinates = newCoords;
	  }
	  newElementsConfigured.push(newElement);
	}
	return newElementsConfigured;
  }
  return [];
};

export const saveElement = async (elements: L1OyMData[], pluginApi: string, newElement: L1OyMData[]): Promise<boolean> => {
  const element = newElement ? newElement : elements.find(element => element.updated === '');

  if (element) {
    const referencePath = element.elementType === 'element' || element.elementType === 'path' ||
	  element.elementType === 'span' || element.elementType === 'link' ? ', "path": "' + element.pathId + '"' : '';
	const json_values = '{"json_values": {' + 
      '"title":"' + element.title + '",' + 
	  '"uid":"' + element.uid + '",' + 
	  '"user":"' + element.user + '",' + 
	  '"version":' + element.version + ',' + 
	  '"groupname":"' + element.elementType + '",' +
	  '"reference": {' + '"id": "' + element.idx + '"' + referencePath + '} }';
    var objectId = element.pathId;
	if (element.elementType === 'link') {
	  objectId = element.linkId;
	} else if (element.elementType === 'span') {
	  objectId = element.spanId;
	} else if (element.elementType === 'segment') {
	  objectId = element.segmentId;
	} else {
	  objectId = element.elementId;
	}
	if ((objectId === undefined || objectId === 'undefined' || objectId === '') && element.uid !== undefined) {
	  objectId = element.uid;
	}
	const elementStyle = JSON.stringify(element.style, null, 2);
	var json_data = ', "json_data":' +
	  '{"id": "' + objectId + '",' +
	  '"idx": "' + element.idx + '",' +
	  '"style":' + elementStyle + ',' +
	  '"import_id": "' + element.importId + '",' +	  
	  '"type": "' + element.subType + '",' +
	  '"inspection_data": { "last_inspector": "' + element.lastInspector + '",' +
		'"report": "' + element.inspectionReport + '",' +
		'"notes": "' + element.inspectionNotes + '" },' +
	  '"media": { "images": "' + element.inspectionMediaData + '" },';

	if (element.elementType !== 'splicer' && element.elementType !== 'splitter') {
	  json_data = json_data + '"technical_details": { ' +
		'"status": "' + element.status + '",' +
		'"installation_date": "' + element.installationDate + '",' +
		'"last_inspection": "' + element.lastInspection + '",' +
		'"next_maintenance": "' + element.nextMaintenance + '",' +
		'"owner": "' + element.owner + '",';
	  
	  if (element.elementType === 'element') {
	    const elevation = element.elevation ? element.elevation : 0;
	    const jsonString = element.relationIds.map((id, index) => ({
		  element_id: id,
		  distances: element.relationDistances[index],
		  capacities: element.relationCapacities[index],
	    }));
	    const sublinkText = JSON.stringify(jsonString, null, 2);
	    json_data = json_data + '"path_id": "' + element.pathId + '",' +
		  '"parents": ["' + element.pathId + '"],' +
		  '"location": {' + '"coordinates": [' + element.coordinates + '],' +
		    '"address": "' + element.address + '",' +
		    '"elevation": ' + elevation +	'},' +
		  '"relations": ' + sublinkText + ',' +
		  '"notes": "' + element.installationNotes + '"} } }';
	  } else if (element.elementType === 'emp') {
	    const elevation = element.elevation ? element.elevation : 0;
	    json_data = json_data + '"collector": "' + element.siteId + '",' +
		  '"parents": ["' + element.pathId + '"],' +
		  '"location": {' + '"coordinates": [' + element.coordinates + '],' +
		    '"address": "' + element.address + '",' +
		    '"elevation": ' + elevation + '},' +
		  '"notes": "' + element.installationNotes + '"} } }';
	  } else if (element.elementType === 'path') {
	    const jsonString = JSON.stringify(element.coordinates);
		json_data = json_data + '"path_id": "' + element.pathId + '",' +
		  '"origin": "' + element.origin + '",' +
		  '"destination": "' + element.destination + '",' +
		  '"geometry": {' + '"coordinates": ' + jsonString + ' },' +
		  '"notes": "' + element.installationNotes + '"} } }';
      } else if (element.elementType === 'span') {
	    const capacity = element.capacity ? element.capacity : 0;
		const jsonString = JSON.stringify(element.coordinates);
		json_data = json_data + '"path_id": "' + element.pathId + '",' +
		  '"span_id": "' + element.spanId + '",' +
		  '"parents": ["' + element.pathId + '"],' +
		  '"capacity": ' + capacity + ',' +
		  '"origin": "' + element.origin + '",' +
		  '"destination": "' + element.destination + '",' +
		  '"geometry": {' + '"coordinates": ' + jsonString + ' },' +
		  '"notes": "' + element.installationNotes + '"} } }';
	  } else if (element.elementType === 'link') {
	    const capacity = element.capacity ? element.capacity : 0;
		json_data = json_data + '"path_id": "' + element.pathId + '",' +
		  '"link_id": "' + element.linkId + '",' +
		  '"parents": ["' + element.pathId + '"],' +
		  '"capacity": ' + capacity + ',' +
		  '"origin": "' + element.origin + '",' +
		  '"destination": "' + element.destination + '",' +
		  '"notes": "' + element.installationNotes + '"} } }';
      } else if (element.elementType === 'segment') {
	    const jsonString = element.sublinkIds.map((id, index) => ({
		  sublink_id: id,
		  buffer: element.sublinkBuffer[index],
		  color: element.sublinkColor[index],
		  attenuation: element.sublinkAttenuation[index],
		  distance: element.sublinkDistance[index],
	    }));
	    const sublinkText = JSON.stringify(jsonString, null, 2);
		const capacity = element.capacity ? element.capacity : 0;
		json_data = json_data + '"link_id": "' + element.linkId + '",' +
		  '"segment_id": "' + element.segmentId + '",'
		  '"parents": ["' + element.linkId + '"],' +
		  '"capacity": ' + capacity + ',' +
	      '"sublinks": ' + sublinkText + ',';
		  '"origin": "' + element.origin + '",' +
		  '"destination": "' + element.destination + '",' +
		  '"notes": "' + element.installationNotes + '"} } }';
	  }
	} else {
	  const jsonString = element.fiberIds.map((id, index) => ({
		fiber_id: id,
		attenuation: element.eventAttenuation[index],
	  }));
	  const sublinkText = JSON.stringify(jsonString, null, 2);
	  const capacity = element.capacity ? element.capacity : 0;
	  json_data = json_data + '"technical_details": { ' +
		'"element_id": "' + element.idx + '",' +
		'"segment_id": "' + element.segmentId + '",' +
		'"path_event_id": "' + element.pathEventId + '",' +
		'"parents": ["' + element.segmentId + '"],' +
		'"capacity": ' + capacity + ',';
	  if (element.elementType === 'splicer') {
		json_data = json_data + '"splicings": ' + sublinkText + ',';
	  } else {
	    json_data = json_data + '"splittings": ' + sublinkText + ',';
	  }
	  json_data = json_data + '"technician": "' + element.technician + '",' + 
		'"installation_date": "' + element.installationDate + '",' +
	    '"notes": "' + element.installationNotes + '"} } }';
	}

	const elementToUpdate = json_values + json_data;
	axios.defaults.baseURL = pluginApi;
    axios.defaults.headers.post['Content-Type'] = 'application/json';
	try {
	  const response = await axios.post(pluginApi, elementToUpdate);
	  if (response.statusText === 'OK') {
        const appEvents = await SystemJS.load('app/core/app_events');
        appEvents.emit(AppEvents.alertSuccess, ['Elemento actualizado correctamente']);
        return true;
      } else {
        const appEvents = await SystemJS.load('app/core/app_events');
        appEvents.emit(AppEvents.alertSuccess, [response.statusText]);
        return false;
      }
	} catch (error) {
      const appEvents = await SystemJS.load('app/core/app_events');
      appEvents.emit(AppEvents.alertError, ['Error al actualizar elemento: ' + error.response.status]);
      return false;
    }
	return false;
  }
};

export const L1OyMTable: FC<l1OyMTableProps> = ({
  renderCount,
  isAdmin,
  onSave,
  onCancel,
  filters,
  isDark,
  width,
  height,
  elements,
  places,
  types,
  sites,
  pluginVariables,
  isKmzAddMode
}) => {
  const [editMode, setEditMode] = useState(false);
  const [deletingRouteId, setDeletingRouteId] = useState<string | undefined>(undefined);
  const [expandedId, setExpandedId] = useState<string | number>();

  const expandItem = useCallback((item: RouteTableItemProps) => setExpandedId(item.id), []);
  const collapseItem = useCallback(() => setExpandedId(undefined), []);

  const statusToString = (value: string) => {
	const valueSelected = valueToStatus.find(ele => ele.value === value);
	if (valueSelected) {
	  return valueSelected.label;
	} else {
	 return null;
	}
  }

  const statusToSubtype = (value: string) => {
	const valueSelected = valueToSubtype.find(ele => ele.value === value);
	if (valueSelected) {
	  return valueSelected.label;
	} else {
	 return null;
	}
  }

  const typeToString = (value: string) => {
	const valueSelected = valueToType.find(ele => ele.value === value);
	if (valueSelected) {
	  return valueSelected.label;
	} else {
	 return null;
	}
  }

  const subtypeToString = (value: string) => {
	const valueSelected = valueToSubtype.find(ele => ele.value === value);
	if (valueSelected) {
	  return valueSelected.label;
	} else {
	 return null;
	}
  }

  const resCols: RouteTableColumnProps[] = [
    {
      id: 'title',
      label: 'Designación',
      renderCell: (item) => {
		return item.data.title.length ? (
          <Text text={item.data.title} enable={item.data.state} />
        ) : (
          <Text text={'-'} />
        );
      },
      size: 5,
    },
    {
      id: 'elementType',
      label: 'Elemento',
      renderCell: (item) => {
		return item.data.elementType.length ? (
          <Text text={typeToString(item.data.elementType)} enable={item.data.state} />
        ) : (
          <Text text={'-'} />
        );
      },
      size: 5,
    },
    {
      id: 'state',
      label: 'Estado',
      renderCell: (item) => {
        const status = statusToString(item.data.state);
		return status !== null ? (
          <Text text={status} enable={true} />
        ) : (
          <Text text={'Desconocido'} enable={false} />
        );
      },
      size: 5,
    },
  ];
  const cols: RouteTableColumnProps[] = [
    {
      id: 'title',
      label: 'Designación',
      renderCell: (item) => {
		return item.data.title.length ? (
          <Text text={item.data.title} enable={item.data.state} />
        ) : (
          <Text text={'-'} />
        );
      },
      size: 5,
    },
    {
      id: 'elementType',
      label: 'Elemento',
      renderCell: (item) => {
		return item.data.elementType.length ? (
          <Text text={typeToString(item.data.elementType)} enable={item.data.state} />
        ) : (
          <Text text={'-'} />
        );
      },
      size: 5,
    },
    {
      id: 'subType',
      label: 'Tipo',
      renderCell: (item) => {
		return item.data.subType.length ? (
          <Text text={subtypeToString(item.data.subType)} enable={item.data.state} />
        ) : (
          <Text text={'-'} />
        );
      },
      size: 5,
    },
    {
      id: 'state',
      label: 'Estado',
      renderCell: (item) => {
        const status = statusToString(item.data.status);
		return status !== null ? (
          <Text text={status} enable={true} />
        ) : (
          <Text text={'Desconocido'} enable={false} />
        );
      },
      size: 5,
    },
    {
      id: 'version',
      label: 'Versión',
      renderCell: (item) => {
		return item.data.version ? (
          <Text text={String(item.data.version)} enable={item.data.state} />
        ) : (
          <Text text={'-'} />
        );
      },
      size: 5,
    }
  ];
  if (isAdmin) {
    cols.push({
	  id: 'user',
	  label: 'Modicado por',
	  renderCell: (item) => {
		return item.data.user ? (
		  <Text text={item.data.user} enable={item.data.state} />
		) : (
		  <Text text={'-'} />
		);
	  }, 
	  size: 5,
	});
	cols.push({
	  id: 'updated',
	  label: 'Fecha',
	  renderCell: (item) => {
		return item.data.updated ? (
		  <Text text={item.data.updated} enable={item.data.state} />
		) : (
		  <Text text={'-'} />
		);
	  }, 
	  size: 6,
	});
  }

  const elementsFiltered = useMemo(
    () => getFilteredElements(
	  elements,
	  true,
	  filters?.title,
	  filters?.elementType,
	  filters?.subType,
	  filters?.place,
	  filters?.hasChange,
	  filters?.version
	), [elements, filters]
  );
  
  const dynamicTableElements = useMemo(
    () => prepareItems(elementsFiltered),
    [elementsFiltered]
  );

  if (elements.length < 1) {
    return (
      <EmptyArea>
        <p>{pluginVariables[3]}</p>
      </EmptyArea>
    );
  }

  return (
    <>
      <DynamicTable
        cols={width > 550 ? cols : resCols}
        items={dynamicTableElements}
        isExpandable={true}
		onSave={onSave}
		onCancel={onCancel}
		filters={filters}
		isDark={isDark}
		isAdmin={isAdmin}
		width={width}
		height={height}
		elements={elements}
		places={places}
		types={types}
		sites={sites}
		pluginVariables={pluginVariables}
		isKmzAddMode={isKmzAddMode}
      />
    </>
  );
};

export interface L1OyMMapProps {
  renderCount: number;
  isAdmin: boolean;
  onImportData: (newElements: L1OyMData[]) => void;
  onSave: (elements: L1OyMData[]) => void;
  onCancel: () => void;
  filters?: { title?: string; elementType?: string; subType?: string;  place?: string, hasChange: boolean, version: number };
  isDark:boolean;
  width: number;
  height: number;
  elements: L1OyMData[];
  places: [];
  types: [];
  sites: [];
  pluginVariables: [];
  isKmzAddMode: any;
}

export const L1OyMMap: FC<L1OyMMapProps> = ({
  renderCount,
  isAdmin,
  onImportData,
  onSave,
  onCancel,
  filters,
  isDark,
  width,
  height,
  elements,
  places,
  types,
  sites,
  pluginVariables,
  isKmzAddMode
}) => {
  const elementsFiltered = useMemo(
    () => getFilteredElements(
	  elements,
	  true,
	  filters?.title,
	  filters?.elementType,
	  filters?.subType,
	  filters?.place,
	  filters?.hasChange,
	  filters?.version
	), [elements, filters]
  );
  
  const dynamicTableElements = useMemo(
    () => prepareItems(elementsFiltered),
    [elementsFiltered]
  );

  if (elements.length < 1) {
    return (
      <EmptyArea>
        <p>{pluginVariables[3]}</p>
      </EmptyArea>
    );
  }

  return (
    <>
      <DynamicMap
        renderCount={renderCount}
		items={dynamicTableElements}
		onImportData={onImportData}
		onSave={onSave}
		onCancel={onCancel}
		filters={filters}
		isDark={isDark}
		isAdmin={isAdmin}
		width={width}
		height={height}
		elements={elements}
		places={places}
		types={types}
		sites={sites}
		pluginVariables={pluginVariables}
		isKmzAddMode={isKmzAddMode}
      />
    </>
  );
};