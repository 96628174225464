import { PanelPlugin } from '@grafana/data';
import { AlarmManagerOptions } from 'types';
import { FieldSelectEditor } from './FieldSelectEditor';
import { AlarmManagerPanel } from './AlarmManagerPanel';

export const plugin = new PanelPlugin<AlarmManagerOptions>(AlarmManagerPanel).setPanelOptions((builder) => {
  return builder
    .addBooleanSwitch({
      path: 'alarmManager',
      name: 'Is Alarm Manager?',
      description: 'Set true if this panel is an Alarms Manager. False for Alerts Manager.',
      category: ['General Settings'],
      defaultValue: true,
    })
    .addTextInput({
      path: 'api',
      name: 'API URL',
      category: ['General Settings'],
      defaultValue: '${api}/update_information_data',
    })
    .addBooleanSwitch({
      path: 'showAddNote',
      name: 'Show add notes',
      description: 'Enable to add/modify notes asociated to the alarm',
      category: ['General Settings'],
      defaultValue: false,
    })
    .addTextInput({
      path: 'addNotesUrl',
      name: 'Add Note URL',
      category: ['General Settings'],
      defaultValue: '',
    })
    .addTextInput({
      path: 'editNotesUrl',
      name: 'Edit Note URL',
      category: ['General Settings'],
      defaultValue: '',
    })
    .addBooleanSwitch({
      path: 'showTitle',
      name: 'show customizable title',
      description: 'Enable title inside plugin',
      category: ['General Settings'],
      defaultValue: true,
    })
    .addTextInput({
      name: 'Title',
      path: 'pluginTitle',
      defaultValue: 'Alarms SNMP Recibidos',
      category: ['General Settings'],
      showIf: (options) => options.showTitle === true,
    })
    .addBooleanSwitch({
      path: 'transparent',
      name: 'Hide panel border',
      category: ['General Settings'],
      defaultValue: true,
    })
    .addBooleanSwitch({
      path: 'showToolbar',
      name: 'Show filter toolbar',
      description: 'Show toolbar with filters',
      category: ['General Settings'],
      defaultValue: true,
    })
    .addTextInput({
      path: 'statusVariable',
      name: 'Alarm status variable name',
      description: 'Variable name to used when status filter is select',
      category: ['General Settings'],
      defaultValue: 'status',
      showIf: (options) => options.showToolbar === true,
    })
    .addTextInput({
      path: 'typeVariable',
      name: 'Alarm Type variable name',
      category: ['General Settings'],
      defaultValue: 'severity',
      showIf: (options) => options.showToolbar === true,
    })
    .addTextInput({
      path: 'assetVariable',
      name: 'Asset variable name',
      category: ['General Settings'],
      defaultValue: 'asset',
      showIf: (options) => options.showToolbar === true,
    })
    .addTextInput({
      path: 'alarmVariable',
      name: 'Alarm ID variable name',
      description: 'Used to apply filters when clic on Alarm name',
      category: ['General Settings'],
      defaultValue: 'alarm',
      showIf: (options) => options.showToolbar === true,
    })
    .addTextInput({
      path: 'hashVariable',
      name: 'Alarm HASH variable name',
      description: 'Used to find similar Alarms or when add notes',
      category: ['General Settings'],
      defaultValue: 'hash',
      showIf: (options) => options.showToolbar === true,
    })
    .addTextInput({
      path: 'elementVariable',
      name: 'Alarm IDX variable name',
      description: 'Used to clean select when clic on Alarm name',
      category: ['General Settings'],
      defaultValue: 'idx',
      showIf: (options) => options.showToolbar === true,
    })
    .addTextInput({
      path: 'selectStatusVariable',
      name: 'Selected Alarm status variable name',
      description: 'Used to apply filters when clic on Alarm card',
      category: ['General Settings'],
      defaultValue: 'statusAlarm',
      showIf: (options) => options.showToolbar === true,
    })
    .addTextInput({
      path: 'selectAlarmVariable',
      name: 'Selected Alarm ID variable name',
      description: 'Used to apply filters when clic on Alarm card',
      category: ['General Settings'],
      defaultValue: 'selectAlarm',
      showIf: (options) => options.showToolbar === true,
    })
    .addBooleanSwitch({
      path: 'showReadAllOptions',
      name: 'Show read All Options',
      description: 'Show toolbar with read All button',
      category: ['General Settings'],
      defaultValue: true,
      showIf: (options) => options.showToolbar === true,
    })
    .addBooleanSwitch({
      path: 'showOnlyUnreadOption',
      name: 'Show message status Options',
      description: 'Show toolbar with read, unread and All filter',
      category: ['General Settings'],
      defaultValue: true,
      showIf: (options) => options.showToolbar === true,
    })
    .addTextInput({
      path: 'alarmReadVariable',
      name: 'Alarm read variable name',
      description: 'Variable name to used when enable only read filter',
      category: ['General Settings'],
      defaultValue: 'read',
      showIf: (options) => options.showOnlyUnreadOption === true,
    })
    .addTextInput({
      path: 'filterDefaultValue',
      name: 'Filters default Value',
      category: ['General Settings'],
      defaultValue: 'Todos',
    })

    .addCustomEditor({
      id: 'dateField',
      path: 'dateField',
      name: 'Date Field',
      category: ['Data Map Settings'],
      defaultValue: 'title',
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'alarmTitleField',
      path: 'alarmTitleField',
      name: 'Alarm Name Field',
      category: ['Data Map Settings'],
      defaultValue: 'alarmtitle',
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'statusField',
      path: 'statusField',
      name: 'Status Field',
      category: ['Data Map Settings'],
      defaultValue: 'status',
      editor: FieldSelectEditor,
    })
    .addBooleanSwitch({
      path: 'usealarmSubtitle',
      name: 'Use Alarm description',
      description: 'Use description inside timeline',
      category: ['Data Map Settings'],
      defaultValue: true,
    })
    .addCustomEditor({
      id: 'alarmSubtitleField',
      path: 'alarmSubtitleField',
      name: 'Alarm message',
      category: ['Data Map Settings'],
      showIf: (config) => config.usealarmSubtitle === true,
      defaultValue: 'description',
      editor: FieldSelectEditor,
    })
    .addTextInput({
      path: 'subtitleTitle',
      name: 'Alarm ID title',
      category: ['Data Map Settings'],
      showIf: (config) => config.usealarmSubtitle === true,
      defaultValue: 'ID',
    })
    .addBooleanSwitch({
      path: 'usealarmDetailedText',
      name: 'Alarm Detailed Info',
      description: 'Allow to use aditional info',
      category: ['Data Map Settings'],
      defaultValue: false,
    })
    .addCustomEditor({
      id: 'alarmDetailedTextField',
      path: 'alarmDetailedTextField',
      name: 'Alarm info Field',
      category: ['Data Map Settings'],
      showIf: (config) => config.usealarmDetailedText === true,
      defaultValue: 'info',
      editor: FieldSelectEditor,
    })
    .addTextInput({
      path: 'infoTitle',
      name: 'Alarm info title',
      category: ['Data Map Settings'],
      showIf: (config) => config.usealarmDetailedText === true,
      defaultValue: 'Información adicional:',
    })
    .addCustomEditor({
      id: 'ticketIdField',
      path: 'ticketIdField',
      name: 'Ticket ID Field',
      category: ['Data Map Settings'],
      defaultValue: 'ticketId',
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'ticketStatusField',
      path: 'ticketStatusField',
      name: 'Ticket Status Field',
      category: ['Data Map Settings'],
      defaultValue: 'ticketStatus',
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'hasNotesField',
      path: 'hasNotesField',
      name: 'Has note Field',
      category: ['Data Map Settings'],
      defaultValue: 'hasnote',
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'isReadField',
      path: 'isReadField',
      name: 'Is read Field',
      category: ['Data Map Settings'],
      defaultValue: 'isread',
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'userReadField',
      path: 'userReadField',
      name: 'User read Field',
      category: ['Data Map Settings'],
      defaultValue: 'readuser',
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'dateReadField',
      path: 'dateReadField',
      name: 'Date read Field',
      category: ['Data Map Settings'],
      defaultValue: 'readdate',
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'typeField',
      path: 'typeField',
      name: 'Alarm Type Field',
      category: ['Data Map Settings'],
      defaultValue: 'type',
      editor: FieldSelectEditor,
    })
    .addTextInput({
      path: 'typeTitle',
      name: 'Alarm Type title',
      category: ['Data Map Settings'],
      defaultValue: 'Tipo:',
    })
    .addCustomEditor({
      id: 'severityField',
      path: 'severityField',
      name: 'Severity Field',
      category: ['Data Map Settings'],
      defaultValue: 'severity',
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'agentField',
      path: 'agentField',
      name: 'Agent Field',
      category: ['Data Map Settings'],
      defaultValue: 'agent',
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'idxField',
      path: 'idxField',
      name: 'Alarm IDX Field',
      category: ['Data Map Settings'],
      defaultValue: 'idx',
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'hashField',
      path: 'hashField',
      name: 'Hash Field',
      category: ['Data Map Settings'],
      defaultValue: 'hash',
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'iconField',
      path: 'iconField',
      name: 'Icon Field',
      category: ['Data Map Settings'],
      defaultValue: 'icon',
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'assetIdField',
      path: 'assetIdField',
      name: 'Asset ID Field',
      description: 'Used to apply filters when clic on title',
      category: ['Data Map Settings'],
      defaultValue: '',
      editor: FieldSelectEditor,
    })
    .addTextInput({
      path: 'assetTitle',
      name: 'Asset title',
      category: ['Data Map Settings'],
      defaultValue: '',
    })

    .addBooleanSwitch({
      path: 'showCardInfo',
      name: 'Display alarm info',
      description: 'Show full info on alarm or only Cartitle',
      category: ['Card Settings'],
      defaultValue: true,
    })
    .addBooleanSwitch({
      path: 'hideControls',
      name: 'Hide TimeLine Controls',
      category: ['Card Settings'],
      defaultValue: false,
    })
    .addBooleanSwitch({
      path: 'flipLayout',
      name: 'Flip TimeLine layout',
      category: ['Card Settings'],
      defaultValue: false,
    })
    .addNumberInput({
      path: 'alarmMinHeight',
      name: 'Card cage min height Size',
      category: ['Card Settings'],
      defaultValue: 15,
    })
    .addNumberInput({
      path: 'alarmFontSize',
      name: 'Card title font size',
      category: ['Card Settings'],
      defaultValue: 12,
    })
    .addNumberInput({
      path: 'circleDimension',
      name: 'Card circle dimension',
      category: ['Card Settings'],
      defaultValue: 24,
    })
    .addBooleanSwitch({
      path: 'useIcon',
      name: 'Use Icon inside circle',
      category: ['Card Settings'],
      defaultValue: true,
    })
    .addBooleanSwitch({
      path: 'useReadMore',
      name: 'Read more option',
      description: 'Show Read more option (vertical orientation)',
      showIf: (config) => config.legendPosition !== 'HORIZONTAL',
      category: ['Card Settings'],
      defaultValue: true,
    })
    .addRadio({
      path: 'legendPosition',
      defaultValue: 'bottom',
      name: 'Orientation',
      category: ['Card Settings'],
      settings: {
        options: [
          {
            value: 'VERTICAL',
            label: 'VERTICAL',
          },
          {
            value: 'VERTICAL_ALTERNATING',
            label: 'VERTICAL_ALTERNATING',
          },
        ],
      },
    })
    .addNumberInput({
      path: 'lineSize',
      name: 'Line width',
      category: ['Card Settings'],
      defaultValue: 1,
    })

    .addTextInput({
      path: 'drillDownLink',
      name: 'More info link base URL',
      category: ['Link to other features'],
      defaultValue: '',
    })
    .addTextInput({
      path: 'drillDownLinkAgent',
      name: 'Agent base URL',
      category: ['Link to other features'],
      defaultValue: '',
    })
    .addTextInput({
      path: 'drillDownLinkTitle',
      name: 'Title alarm base URL',
      category: ['Link to other features'],
      defaultValue: '',
    })
    .addTextInput({
      path: 'error1',
      name: 'Query error message',
      category: ['Error Messages'],
      defaultValue: 'Error al obtener datos',
    })
    .addTextInput({
      path: 'error2',
      name: 'On null data message',
      category: ['Error Messages'],
      defaultValue: 'No existen TRAPs para mostrar',
    })
    .addTextInput({
      path: 'error3',
      name: 'On plugin load error message',
      category: ['Error Messages'],
      defaultValue: 'Error al cargar el complemento',
    })
    .addTextInput({
      path: 'error4',
      name: 'Size error message',
      category: ['Error Messages'],
      defaultValue: 'Error: contenedor demasiado pequeño',
    });
});
