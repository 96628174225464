import { css, cx } from '@emotion/css';
import React, { ReactNode, useEffect, useState } from 'react';
import { locationService, getTemplateSrv } from '@grafana/runtime';
import { NetMonitorTheme2 } from '@grafana/data';
import { IconButton, Pagination, useStyles2, Icon } from '@grafana/ui';
import { usePagination } from 'app/features/alerting/unified/hooks/usePagination';
import { getPaginationStyles } from 'app/features/alerting/unified/styles/pagination';
import { HelpExpandedForm, HelpExpandedToc } from './HelpExpandedForm';
import { updateElement, cleanElements, saveElement } from './HelpTable';
import { HelpData } from './L1OyMManager';
import { MapComponent } from './PointPickerModal';
import { L1OyMMapForm } from './L1OyMMapForm';

interface DynamicTablePagination {
  itemsPerPage: number;
}

export interface DynamicTableColumnProps<T = unknown> {
  id: string | number;
  label: string;
  renderCell: (item: DynamicTableItemProps<T>, index: number) => ReactNode;
  size?: number | string;
}

export interface DynamicTableItemProps<T = unknown> {
  id: string | number;
  data: T;
  renderExpandedContent?: () => ReactNode;
}

export interface DynamicTocProps<T = unknown> {
  cols: Array<DynamicTableColumnProps<T>>;
  items: Array<DynamicTableItemProps<T>>;
  isExpandable?: boolean;
  pagination?: DynamicTablePagination;
  paginationStyles?: string;
  onSave: (elements: HelpData[]) => void;
  onCancel: () => void;
  renderPrefixHeader?: () => ReactNode;
  renderPrefixCell?: (
    item: DynamicTableItemProps<T>,
    index: number,
    items: Array<DynamicTableItemProps<T>>
  ) => ReactNode;
  isDark:boolean;
  isAdmin: boolean;
  footerRow?: JSX.Element;
  width: number;
  height: number;
  elements: HelpData[];
  pluginVariables: [];
}

export const DynamicToc = <T extends object>({
  cols,
  items,
  isExpandable,
  pagination,
  paginationStyles,
  onSave,
  onCancel,
  renderPrefixHeader,
  renderPrefixCell,
  isDark,
  isAdmin,
  footerRow,
  width,
  height,
  elements,
  pluginVariables
}: DynamicTocProps<T>) => {
  const defaultPaginationStyles = useStyles2(getPaginationStyles);
  const styles = useStyles2(getStyles(cols, isExpandable, !!renderPrefixHeader, isDark));
  const [expandedIds, setExpandedIds] = useState<Array<DynamicTableItemProps['id']>>([]);

  const toggleExpanded = (item: DynamicTableItemProps<T>) => {
    setExpandedIds(
      expandedIds.includes(item.id) ? expandedIds.filter((itemId) => itemId !== item.id) : [item.id]
    );
  };

  const itemsPerPage = pagination?.itemsPerPage ?? items.length;
  const { page, numberOfPages, onPageChange, pageItems } = usePagination(items, 1, itemsPerPage);

  console.log(items, expandedIds);
  if (items.length === 1 && items[0].data.version === 0) {
	if (!expandedIds.includes(item[0].data.id)) {
	  setExpandedIds([item[0].data.id]);
	}
  }

  if (pluginVariables[7]) {
    return (
      <div className={styles.tocContainer}>
        {pageItems.map((item, index) => {
		  return (
            <div
              className={styles.tocRow}
              key={`${item.id}-${index}`}
            >
              <div className={styles.expandedTocRow}>
                <HelpExpandedToc
				  element={item.data}
				  isDark={isDark}
				  isAdmin={isAdmin}
				  width={width - 20}
				  height={height - 50}
				  elements={elements}
				  pluginVariables={pluginVariables}
				/>
              </div>
			</div>
		  );
        })}
      </div>
    );
  } else {
    return (
      <div className={styles.tocContainer}>
        {pageItems.map((item, index) => {
		  const isItemExpanded = expandedIds.includes(item.id);
		  return (
            <div
              className={isItemExpanded ? styles.tocRow : styles.row}
              key={`${item.id}-${index}`}
            >
              {renderPrefixCell && renderPrefixCell(item, index, items)}
              {(isExpandable) && (
                <div className={cx(styles.iconCell, styles.expandCell)}>
                  <IconButton
                    aria-label={`${isItemExpanded ? 'Collapse' : 'Expand'} row`}
                    size={'lg'}
                    className={styles.expandButton}
                    name={isItemExpanded ? 'minus-circle' : 'plus-circle'}
                    onClick={() => toggleExpanded(item)}
                    type="button"
                  />
                </div>
              )}
              {!isItemExpanded && cols.map((col) => (
				<div 
				  className={cx(styles.cell, styles.bodyCell)} 
				  data-column={col.label} 
				  key={`${item.id}-${col.id}`}
				>
                  {col.renderCell(item, index)}
                </div>
              ))}
              {isItemExpanded && (
                <div className={styles.expandedTocRow}>
                  <HelpExpandedToc
					element={item.data}
					isDark={isDark}
					isAdmin={isAdmin}
					width={width - 20}
					height={height - 50}
					elements={elements}
					pluginVariables={pluginVariables}
				  />
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  }
};

export interface DynamicTableProps<T = unknown> {
  cols: Array<DynamicTableColumnProps<T>>;
  items: Array<DynamicTableItemProps<T>>;
  isExpandable?: boolean;
  pagination?: DynamicTablePagination;
  paginationStyles?: string;
  onSave: (elements: HelpData[]) => void;
  onCancel: () => void;
  renderPrefixHeader?: () => ReactNode;
  renderPrefixCell?: (
    item: DynamicTableItemProps<T>,
    index: number,
    items: Array<DynamicTableItemProps<T>>
  ) => ReactNode;
  isDark:boolean;
  isAdmin: boolean;
  footerRow?: JSX.Element;
  width: number;
  height: number;
  elements: HelpData[];
  pluginVariables: [];
}

export const DynamicTable = <T extends object>({
  cols,
  items,
  isExpandable,
  pagination,
  paginationStyles,
  onSave,
  onCancel,
  renderPrefixHeader,
  renderPrefixCell,
  isDark,
  isAdmin,
  footerRow,
  width,
  height,
  elements,
  pluginVariables
}: DynamicTableProps<T>) => {
  const defaultPaginationStyles = useStyles2(getPaginationStyles);
  const styles = useStyles2(getStyles(cols, isExpandable, !!renderPrefixHeader, isDark));
  const [expandedIds, setExpandedIds] = useState<Array<DynamicTableItemProps['id']>>(pluginVariables[9] || pluginVariables[15] ? [0] : []);

  const toggleExpanded = (item: DynamicTableItemProps<T>) => {
    setExpandedIds(
      expandedIds.includes(item.id) ? expandedIds.filter((itemId) => itemId !== item.id) : [item.id]
    );
  };
  
  const itemsPerPage = pagination?.itemsPerPage ?? items.length;
  const { page, numberOfPages, onPageChange, pageItems } = usePagination(items, 1, itemsPerPage);

  return (
    <>
      <div className={styles.container} style={{ height: height }}>
        <div className={styles.header}>
          {renderPrefixHeader && renderPrefixHeader()}
          {isExpandable && <div className={styles.cell} />}
          {cols.map((col) => (
            <div className={styles.cell} key={col.id}>
              {col.label}
            </div>
          ))}
        </div>
        {pageItems.map((item, index) => {
		  const isItemExpanded = expandedIds.includes(item.id);
		  return (
            <div
              className={styles.row}
              key={`${item.id}-${index}`}
            >
              {renderPrefixCell && renderPrefixCell(item, index, items)}
              {(isExpandable) && (
                <div className={cx(styles.iconCell, styles.expandCell)}>
                  <IconButton
                    aria-label={`${isItemExpanded ? 'Collapse' : 'Expand'} row`}
                    size={'xl'}
                    className={styles.expandButton}
                    name={isItemExpanded ? 'angle-down' : 'angle-right'}
                    onClick={() => toggleExpanded(item)}
                    type="button"
                  />
                </div>
              )}
              {cols.map((col) => (
				<div 
				  className={cx(styles.cell, styles.bodyCell)} 
				  data-column={col.label} 
				  key={`${item.id}-${col.id}`}
				>
                  {col.renderCell(item, index)}
                </div>
              ))}
              {isItemExpanded && (
                <div className={styles.expandedContentRow}>
                  <HelpExpandedForm
					onCancel={(item) => {
					  onCancel();
					  toggleExpanded(expandedIds);
					}}
					onSave={(item, typeOfUpdate) => {
					  const actualElements = cleanElements(items);
					  const newElements = updateElement(actualElements, item);
					  if (typeOfUpdate === 'files') {
					    saveElement(newElements, pluginVariables[0]).then((resultado) => {
						  if (!resultado) {
						    onCancel();
						  }
					    }).catch((error) => {
						  console.error('Error al actualizar archivos:', error);
						  onCancel();
					    });				
					  } else {
					    saveElement(newElements, pluginVariables[0]).then((resultado) => {
						  if (resultado && typeOfUpdate === 'full') {
						    onSave(newElements);
						    if (pluginVariables[9] || pluginVariables[15]) {
							  let queryMap = {
							    [`var-${pluginVariables[6]}`]: item.uid,
							    [`var-${pluginVariables[12]}`]: '',
							  };
							  try {
							    locationService.partial(queryMap, true);
							  } catch (error) {
							    console.error(error);
							  }
						    }
						  } else if (typeOfUpdate === 'full') {
						    onCancel();
						  }
					    }).catch((error) => {
						  console.error('Error al guardar el elemento:', error);
						  onCancel();
					    });
					  }
					}}
					element={item.data}
					isDark={isDark}
					isAdmin={isAdmin}
					width={width}
					elements={elements}
					pluginVariables={pluginVariables}
				  />
                </div>
              )}
            </div>
          );
        })}
        {footerRow && <div className={cx(styles.row, styles.footerRow)}>{footerRow}</div>}
      </div>
      {pagination && (
        <Pagination
          className={cx(defaultPaginationStyles, paginationStyles)}
          currentPage={page}
          numberOfPages={numberOfPages}
          onNavigate={onPageChange}
          hideWhenSinglePage
        />
      )}
    </>
  );
};

const getStyles = <T extends unknown>(
  cols: Array<DynamicTableColumnProps<T>>,
  isExpandable: boolean,
  hasPrefixCell: boolean,
  isDark: boolean
) => {
  const fontColor = isDark ? '#D8DFE9' : '#23282E';
  let sizes = ['auto'];
  
  if (cols !== null) {
    sizes = cols.map((col) => {
      if (!col.size) {
        return 'auto';
      }
      if (typeof col.size === 'number') {
        return `${col.size}fr`;
      }
      return col.size;
    });
  }

  if (isExpandable) {
    sizes.unshift('calc(1em + 16px)');
  }

  if (hasPrefixCell) {
    sizes.unshift('0');
  }

  return (theme: NetMonitorTheme2) => ({
    tocContainer: css`
      color: ${fontColor};
      font-size: 12px;
	  overflow: auto;
	  padding-left: 3px;
    `,
    container: css`
      color: ${fontColor};
      font-size: 12px;
	  overflow: auto;
	  padding-left: 3px;
    `,
    header: css`
      display: grid;
      grid-template-columns: ${sizes.join(' ')};
      grid-template-rows: 1fr auto;
      border-bottom: 1px solid ${theme.v1.colors.formInputBorder};
      font-weight: 600;
    `,
	tocRow: css`
      display: flex;
	  height: 100%;
	  width: 100%;

      &:nth-child(2n + 1) {
        background-color: ${theme.colors.background.secondary};
      }

      &:nth-child(2n) {
        background-color: ${theme.colors.background.primary};
      }
    `,
    row: css`
      display: grid;
      grid-template-columns: ${sizes.join(' ')};
      grid-template-rows: 1fr auto;

      &:nth-child(2n + 1) {
        background-color: ${theme.colors.background.secondary};
      }

      &:nth-child(2n) {
        background-color: ${theme.colors.background.primary};
      }
    `,
    footerRow: css`
      display: flex;
      padding: 12px 4px 0px 4px;
    `,
    cell: css`
      align-items: center;
      margin-left: -2px;
      padding:  10px 0px 0px 0px;
    `,
    iconCell: css`
      margin-left: -5px;
      padding:  14px 0px 0px 2px;

      ${theme.breakpoints.down('sm')} {
        padding:  12px 0px 0px 0px;
        grid-template-columns: 1fr;
      }
    `,
    bodyCell: css`
      overflow: hidden;
    `,
    expandCell: css`
      justify-content: center;
    `,
    expandedTocRow: css`
      padding: 0 ${theme.spacing(1)} 0 ${theme.spacing(2)};
      position: relative;
	  width: 100%;
    `,
	expandedContentRow: css`
      grid-column-end: ${sizes.length + 1};
      grid-column-start: ${hasPrefixCell ? 3 : 2};
      grid-row: 2;
      padding: 0 ${theme.spacing(3)} 0 ${theme.spacing(1)};
      position: relative;

      ${theme.breakpoints.down('sm')} {
        grid-column-start: 2;
        border-top: 1px solid ${theme.colors.border.strong};
        grid-row: auto;
        padding: ${theme.spacing(1)} 0 0 0;
      }
    `,
    expandButton: css`
      margin-right: 0;
      display: block;
    `,
    expandIcon: css`
      margin-top: 5px;
      margin-right: 0;
      display: block;
    `,
  });
};
