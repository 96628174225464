import React, { useState, useEffect } from 'react';
import { css, cx } from '@emotion/css';
import axios from 'axios';
import { crc32 } from 'hash-wasm';
import {
  Icon,
  Field,
  Label,
  HorizontalGroup,
  Input,
  useStyles2,
} from '@grafana/ui';
import { AppEvents } from '@grafana/data';

export interface ImageUploadFormProps {
  isAdmin: boolean;
  isDark: boolean;
  width: number;
  imagesPath: [];
  imagesFile: [];
  imagesLabel: [];
  uidx: string;
  onImagesChange: (images: { path: string; label: string; file: string }[]) => void;
  pluginVariables: [];
}

export const ImageUploadForm: FC<ImageUploadFormProps> = ({
  isAdmin,
  isDark,
  width,
  imagesPath,
  imagesLabel,
  imagesFile,
  uidx,
  onImagesChange,
  pluginVariables,
}) => {
  const styles = useStyles2(getStyles(isDark, width));
  const [currentImages, setCurrentImages] = useState<{ path: string; label: string; file: string }[]>([]);
  const [file, setFile] = useState<File | null>(null);
  const [label, setLabel] = useState<string>('');
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
	const actualImages: [] = [];
	if (imagesPath !== undefined) {
	  for (let i = 0; i < imagesPath.length; i++) {	
	    let newImage = {
	      path: imagesPath[i],
		  label: imagesLabel[i] || '',
		  file: imagesFile[i] || '',
	    }
	    actualImages.push(newImage);
      }
	  setCurrentImages(actualImages);
	}
  }, [imagesPath, imagesLabel, imagesFile]);

  const handleLabelChange = (index: number, value: string) => {
    let updatedImages = currentImages;
	updatedImages[index].label = value;
	setCurrentImages(updatedImages);
	onImagesChange(updatedImages);
  };

  const handleDelete = async (filename) => {
    const confirm = window.confirm(`¿Estás seguro de que deseas eliminar el archivo "${filename}"?`);
    if (!confirm) return;

    try {
        const response = await axios.delete(`${pluginVariables[18]}/delete/${filename}`);
        if (response.status === 200) {
          const appEvents = await SystemJS.load('app/core/app_events');
          appEvents.emit(AppEvents.alertSuccess, ['Archivo eliminado exitosamente.']);
        }
    } catch (error) {
      const appEvents = await SystemJS.load('app/core/app_events');
      appEvents.emit(AppEvents.alertError, ['Ocurrió un error al eliminar el archivo.']);

    }
	const updatedImages = currentImages.filter(img => img.path !== `${filename}`);
	setCurrentImages(updatedImages);
	onImagesChange(updatedImages);
	setFile(null);
	setLabel('');
	setUploading(false);
  };

  const generateCredentials = async () => {
    const baseString = 'netmonitor';
    const timestamp = new Date().toISOString().split('T')[0];
    const username = `${baseString}_${timestamp}`;
    const password = await crc32(username);
    return { username, password: password.toString() };
  };

  const handleUpload = async () => {
    if (!file || !label) {
      alert('Por favor selecciona un archivo e ingresa una etiqueta.');
      return;
    }
    const MAX_FILE_SIZE_MB = pluginVariables[19];
    const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;

    if (file.size > MAX_FILE_SIZE_BYTES) {
      alert(`El archivo excede el tamaño máximo permitido de ${MAX_FILE_SIZE_MB} MB.`);	
      return;
    }

    setUploading(true);

    try {
      const credentials = await generateCredentials();
	  const auth = `Basic ${btoa(`${credentials.username}:${credentials.password}`)}`;
      const formData = new FormData();
      formData.append('file', file);
      formData.append('username', credentials.username);
      formData.append('password', credentials.password);
      formData.append('docId', uidx);
      formData.append('label', label);
      const response = await axios.post(`${pluginVariables[18]}/upload`, formData, {
        headers: {
          'authorization': auth,
		  'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        const { path, fileLink } = response.data;
		const updatedImages = currentImages;
	    let newImage = {
	      path: path,
		  label: label,
		  file: fileLink,
	    }
	    updatedImages.push(newImage);
		setCurrentImages(updatedImages);
        onImagesChange(updatedImages);
        setFile(null);
        setLabel('');
      }
    } catch (error) {
      const appEvents = await SystemJS.load('app/core/app_events');
      appEvents.emit(AppEvents.alertError, ['Ocurrió un error al cargar la imagen.']);
    } finally {
      setUploading(false);
    }
  };

  return (
    <div>
	  {isAdmin && (
		<HorizontalGroup key={'new_conexions'} align="flex-start" height={'30px'} >
		  <div className={styles.addBtnRow}>
			<input
			  type="file"
			  accept=".jpg,.jpeg,.png,.bmp"
			  onChange={(e) => setFile(e.target.files?.[0] || null)}
			  style={{ display: 'none' }} 
			  id="img-upload"
			/>
			<label 
			  htmlFor="img-upload" 
			  className={styles.importButton} 
			>
			  <div className={styles.button_icon}>
				<Icon name="add-file-fill" size="lg" />
			  </div>
			  {'Seleccionar'}
			</label>
			{file !== null && (
			  <input
			    type="text"
			    className={styles.input} 
			    value={file.name}
			    disabled={true}
			  />
			)}
			<input
			  type="text"
			  className={styles.input} 
			  placeholder="Etiqueta de la imagen"
			  value={label}
			  onChange={(e) => setLabel(e.target.value)}
			/>
			<button 
			  type="button" 
			  className={styles.okButton} 
			  onClick={handleUpload} 
			  disabled={uploading}
			  title={'Cargar la imagen seleccionada'}
			>
			  <div className={styles.button_icon}>
			    <Icon name="up-round" size="lg" />
			  </div>
			  {uploading ? 'Subiendo...' : 'Cargar Imagen'}
			</button>
		  </div>
		</HorizontalGroup>
	  )}
	  {currentImages && currentImages.length > 0 && (
		<HorizontalGroup key={'images'} align="flex-start" height={'25px'} >
		  <div className={styles.addBtnRow}>
		    <Label className={styles.nameContainer} >{'Etiqueta'}</Label>
		    <Label className={styles.nameContainer} >{'Url'}</Label>
		  </div>
		</HorizontalGroup>
	  )}
		{currentImages && currentImages.map((img, index) => (
		  <HorizontalGroup key={'images_list'} align="flex-start" height={'35px'} >
		  <div className={styles.addBtnRow}>
			<Field className={styles.nameContainer}>
			  <Input
				aria-label={`label ${index + 1}`}
				className={styles.input}
				type="string"
				value={img.label}
				onChange={(e) => handleLabelChange(index, e.currentTarget.value)}
				disabled={!isAdmin}
				placeholder="Etiqueta..."
				title="Etiqueta empleada para identificar la imagen"
			  />
			</Field>
			<Field className={styles.nameContainer}>
			  <Input
				aria-label={`file ${index + 1}`}
				className={styles.input}
				type="string"
				value={img.file}
				disabled={true}
				title="Link para descarga"
			  />
			</Field>
			<button 
			  type="button" 
			  onClick={() => {
				window.open(img.file, "_blank");
			  }}
			  className={styles.button}
			> 
			  <div className={styles.button_icon}>
				<Icon name="image-v" size="lg" />
			  </div>
			</button>
			{isAdmin && (
			  <button 
			   type="button" 
			   onClick={() => handleDelete(img.path)}
			   className={styles.button}
			  > 
			    <div className={styles.del_icon}>
				  <Icon name="trash-circle-fill" size="lg" />
			    </div>
			  </button>
			)}
		  </div>
		  </HorizontalGroup>
	    ))}
    </div>
  );
};

export interface FileUploadFormProps {
  isAdmin: boolean;
  isDark: boolean;
  width: number;
  filesPath: [];
  filesFile: [];
  filesLabel: [];
  uidx: string;
  onFilesChange: (files: { path: string; label: string; file: string }[]) => void;
  pluginVariables: [];
}

export const FileUploadForm: FC<FileUploadFormProps> = ({
  isAdmin,
  isDark,
  width,
  filesPath,
  filesLabel,
  filesFile,
  uidx,
  onFilesChange,
  pluginVariables,
}) => {
  const styles = useStyles2(getStyles(isDark, width));
  const [currentFiles, setCurrentFiles] = useState<{ path: string; label: string; file: string }[]>([]);
  const [currentFile, setCurrentFile] = useState<File | null>(null);
  const [label, setLabel] = useState<string>('');
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
	const actualFiles: [] = [];
	if (filesPath !== undefined) {
	  for (let i = 0; i < filesPath.length; i++) {	
	    let newFile = {
	      path: filesPath[i],
		  label: filesLabel[i] || '',
		  file: filesFile[i] || '',
	    }
	    actualFiles.push(newFile);
      }
	  setCurrentFiles(actualFiles);
	}
  }, [filesPath, filesLabel, filesFile]);

  const handleLabelChange = (index: number, value: string) => {
    let updatedFiles = currentFiles;
	updatedFiles[index].label = value;
	setCurrentFiles(updatedFiles);
	onFilesChange(updatedFiles);
  };

  const handleDeleteFile = async (filename) => {
    const confirm = window.confirm(`¿Estás seguro de que deseas eliminar el archivo "${filename}"?`);
    if (!confirm) return;

    try {
        const response = await axios.delete(`${pluginVariables[18]}/delete/${filename}`);
        if (response.status === 200 || response.status === 404) {
          const appEvents = await SystemJS.load('app/core/app_events');
          appEvents.emit(AppEvents.alertSuccess, ['Archivo eliminado exitosamente.']);
        }
    } catch (error) {
      const appEvents = await SystemJS.load('app/core/app_events');
      appEvents.emit(AppEvents.alertError, ['Ocurrió un error al eliminar el archivo.']);
    }
	const updatedFiles = currentFiles.filter(file => file.path !== `${filename}`);
	setCurrentFiles(updatedFiles);
	onFilesChange(updatedFiles);
	setCurrentFile(null);
	setLabel('');
	setUploading(false);
  };

  const generateCredentials = async () => {
    const baseString = 'netmonitor';
    const timestamp = new Date().toISOString().split('T')[0];
    const username = `${baseString}_${timestamp}`;
    const password = await crc32(username);
    return { username, password: password.toString() };
  };

  const handleUpload = async () => {
    if (!currentFile || !label) {
      alert('Por favor selecciona un archivo e ingresa una etiqueta.');
      return;
    }
    const MAX_FILE_SIZE_MB = pluginVariables[20];
    const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;

    if (currentFile.size > MAX_FILE_SIZE_BYTES) {
      alert(`El archivo excede el tamaño máximo permitido de ${MAX_FILE_SIZE_MB} MB.`);	
      return;
    }

    setUploading(true);

    try {
      const credentials = await generateCredentials();
	  const auth = `Basic ${btoa(`${credentials.username}:${credentials.password}`)}`;
      const formData = new FormData();
      formData.append('file', currentFile);
      formData.append('username', credentials.username);
      formData.append('password', credentials.password);
      formData.append('docId', uidx);
      formData.append('label', label);
      const response = await axios.post(`${pluginVariables[18]}/upload`, formData, {
        headers: {
          'authorization': auth,
		  'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        const { path, fileLink } = response.data;
		const updatedFiles = currentFiles;
	    let newImage = {
	      path: path,
		  label: label,
		  file: fileLink,
	    }
	    updatedFiles.push(newImage);
		setCurrentFiles(updatedFiles);
        onFilesChange(updatedFiles);
        setCurrentFile(null);
        setLabel('');
      }
    } catch (error) {
      const appEvents = await SystemJS.load('app/core/app_events');
      appEvents.emit(AppEvents.alertError, ['Ocurrió un error al cargar la imagen.']);
    } finally {
      setUploading(false);
    }
  };

  return (
    <div>
	  {isAdmin && (
		<HorizontalGroup key={'new_conexions'} align="flex-start" height={'30px'} >
		  <div className={styles.addBtnRow}>
			<input
			  type="file"
			  accept=".pdf,.dxf,.json,.csv,.txt,.doc"
			  onChange={(e) => setCurrentFile(e.target.files?.[0] || null)}
			  style={{ display: 'none' }} 
			  id="file-upload"
			/>
			<label 
			  htmlFor="file-upload" 
			  className={styles.importButton} 
			>
			  <div className={styles.button_icon}>
				<Icon name="add-file-fill" size="lg" />
			  </div>
			  {'Seleccionar'}
			</label>
			{currentFile !== null && (
			  <input
			    type="text"
			    className={styles.input} 
			    value={currentFile.name}
			    disabled={true}
			  />
			)}
			<input
			  type="text"
			  className={styles.input} 
			  placeholder="Etiqueta para el documento"
			  value={label}
			  onChange={(e) => setLabel(e.target.value)}
			/>
			<button 
			  type="button" 
			  className={styles.okButton} 
			  onClick={handleUpload} 
			  disabled={uploading}
			  title={'Cargar el documento seleccionado'}
			>
			  <div className={styles.button_icon}>
			    <Icon name="up-round" size="lg" />
			  </div>
			  {uploading ? 'Subiendo...' : 'Cargar Documento'}
			</button>
		  </div>
		</HorizontalGroup>
	  )}
	  {currentFiles && currentFiles.length > 0 && (
		<HorizontalGroup key={'files'} align="flex-start" height={'25px'} >
		  <div className={styles.addBtnRow}>
		    <Label className={styles.nameContainer} >{'Etiqueta'}</Label>
		    <Label className={styles.nameContainer} >{'Url'}</Label>
		  </div>
		</HorizontalGroup>
	  )}
		{currentFiles && currentFiles.map((file, index) => (
		  <HorizontalGroup key={'files_list'} align="flex-start" height={'35px'} >
		  <div className={styles.addBtnRow}>
			<Field className={styles.nameContainer}>
			  <Input
				aria-label={`file_label ${index + 1}`}
				className={styles.input}
				type="string"
				value={file.label}
				onChange={(e) => handleLabelChange(index, e.currentTarget.value)}
				disable={!isAdmin}
				placeholder="Etiqueta..."
				title="Etiqueta empleada para identificar el documento"
			  />
			</Field>
			<Field className={styles.nameContainer}>
			  <Input
				aria-label={`file_name ${index + 1}`}
				className={styles.input}
				type="string"
				value={file.file}
				disabled={true}
				title="Link para descarga"
			  />
			</Field>
			<button 
			  type="button" 
			  onClick={() => {
				window.open(file.file, "_blank");
			  }}
			  className={styles.button}
			> 
			  <div className={styles.button_icon}>
				<Icon name="file-download" size="lg" />
			  </div>
			</button>
			{isAdmin && (
			  <button 
			   type="button" 
			   onClick={() => handleDeleteFile(file.path)}
			   className={styles.button}
			  > 
			    <div className={styles.del_icon}>
				  <Icon name="trash-circle-fill" size="lg" />
			    </div>
			  </button>
			)}
		  </div>
		  </HorizontalGroup>
	    ))}
    </div>
  );
};

const getStyles = (isDark: boolean, width: number) => {
  const cancelButtonColor = isDark ? '#F74545' : '#FB3333';
  const okButtonColor = isDark ? '#557FFF' : '#6C63FE';
  const testButtonColor = isDark ? '#23282E' : '#EFF4FA';
  const importButtonBack = isDark ? '#58A956' : '#24AB00';
  const buttonBorder = isDark ? '#44444c' : '#9DA5B8';
  const textColor = isDark ? '#EFF4FA' : '#23282E';
  return (theme: NetMonitorTheme2) => ({
    nameContainer: css`
	  width: ${width * 0.4}px;
	  min-width: 150px;
	  margin-right: 15px;
	  color: ${textColor};
    `,
    input: css`
      flex: 1;
      width: 95%;
	  margin-left: 5px;
	  margin-right: 5px;
	  height: 24px;
	  border: 1px solid ${buttonBorder};
      border-radius: 5px;
	  align-self: center;
    `,
    addBtnRow: css`
      width: ${width - 100}px;
	  display: inline-flex;
      }
    `,
    cancelButton: css`
      background-color: ${cancelButtonColor};
      border: 1px solid ${buttonBorder};
      box-shadow: 4px 2px 4px 0px ${buttonBorder};
      color: #EFF4FA;
      border-radius: 10px;
      vertical-align: middle;
	  align-items: center;
      justify-content: center;
      display: inline-flex;
      align-content: center;
      cursor: pointer;
      max-width: 150px;
      min-width: 32px;
      margin-left: ${theme.spacing.xs};
	  height: 24px;
	  padding: 0px 10px 0px 0px;
    `,
    okButton: css`
      background-color: ${okButtonColor};
      border: 1px solid ${buttonBorder};
      box-shadow: 4px 2px 4px 0px ${buttonBorder};
      color: #EFF4FA;
      border-radius: 10px;
      vertical-align: middle;
	  align-items: center;
      justify-content: center;
      display: inline-flex;
      align-content: center;
      cursor: pointer;
      max-width: 150px;
      min-width: 32px;
      margin-left: ${theme.spacing.xs};
	  height: 24px;
	  padding: 0px 10px 0px 0px;
    `,
    importButton: css`
      background-color: ${importButtonBack};
      border: 1px solid ${buttonBorder};
      box-shadow: 4px 2px 4px 0px ${buttonBorder};
      color: #EFF4FA;
      border-radius: 10px;
      vertical-align: middle;
	  align-items: center;
      justify-content: center;
      display: inline-flex;
      align-content: center;
      cursor: pointer;
	  font-size: 12px;
	  font-weight: 400;
      max-width: 150px;
      min-width: 32px;
      margin-left: 10px;
	  margin-right: 10px;
	  height: 24px;
	  padding: 0px 10px 0px 2px;
    `,
    button: css`
      background-color: transparent;
      border: none;
      color: ${textColor};
      vertical-align: middle;
	  align-items: center;
      justify-content: center;
      display: inline-flex;
      align-content: center;
      cursor: pointer;
      width: 32px;
      margin-left: ${theme.spacing.xs};
	  height: 24px;
	  padding: 0px 4px 0px 4px;
    `,
    add_icon: css`
      border: none;
      min-width: 24px;
	  margin-right: 5px;
	  margin-top: -10px;
	  color: ${okButtonColor};
    `,
    button_icon: css`
      border: none;
      min-width: 24px;
	  margin-right: 5px;
    `,
  });
};
