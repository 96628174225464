import React, { Component } from 'react';
import { config } from '@grafana/runtime';
import './css/netmonitor-tabs.css';
import { changeUrl, getNetMonitorVariableValue, openUrl, backUrl, updateBackUrl, setBackUrl } from './utils';
import { TabspickerButtonProps, GenericButtonProps, GenericIndicatorProps, BackButtonProps } from 'types';
import { Icon } from '@grafana/ui';

export class TabsButton extends Component<TabspickerButtonProps> {
  constructor(props: any) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    if (this.props.variable !== '') {
      const queryMap = {
        [`var-${this.props.variable}`]: this.props.value,
      };
      changeUrl(queryMap, this.props.useBack, this.props.varBack, this.props.varBack1);
    }
  }

  componentDidMount() {
    if (this.props.id === 1 && this.props.varBack !== undefined && this.props.varBack !== 'null') {
      setBackUrl(this.props.varBack, this.props.varBack1);
    }
  }

  render() {
    const actualValue = getNetMonitorVariableValue(this.props.variable);
	const isDark = config.theme.isDark || false;
    const title = this.props.title || '';
	const buttonStyle = isDark ? 'white_dark' : 'black_light';
	const buttonColor = isDark ? this.props.style + '_dark' : this.props.style + '_light';
    const tab_style = this.props.style === 'default' ? 'white' : this.props.style;
	const tab_background = isDark && tab_style === 'white' ? 'white_dark' : tab_style;
    const classIcon = this.props.icon !== undefined ? this.props.icon : 'refresh';
    const classTab = isDark ? 'tab_dark' : 'tab_light';
    const classButton = isDark ? 'tab_unselect tab_face tab_black_disable' : 'tab_unselect tab_face tab_white_disable';
    const classSelected = 'tab_selected tab_face tab_' + tab_background;

	const backButtonClass = this.props.style === 'default' ?
	  'tab_auxbutton tab_aux_' + buttonStyle + ' tab_radius-10' :
	  'tab_auxbutton tab_aux_' + buttonColor + ' tab_radius-10';

    if (actualValue === this.props.value) {
      return (
        <div className={classTab} id={this.props.id}>
          <button className={classSelected} title={title}>
            <div className="tab_icon">
              <Icon name={classIcon} size="md" />
            </div>
            <p className="tab_title">{getTitleUrl(this.props)}</p>
          </button>
        </div>
      );
    } else {
      if (this.props.variable === 'Volver' && this.props.value === 'Volver') {
        return (
          <div className="tab_button" id={this.props.id}>
            <button
              className={backButtonClass}
              onClick={() => backUrl(this.props.varBack, this.props.varBack1)}
              title={title}
            >
              <div className="tab_icon">
                <Icon name={classIcon} size="lg" />
              </div>
              <p className="tab_title">{getTitleUrl(this.props)}</p>
            </button>
          </div>
        );
      } else if (this.props.variable === 'Cerrar' && this.props.value === 'Cerrar') {
        return (
          <div className="tab_button" id={this.props.id}>
            <button
              className={backButtonClass}
              onClick={() => window.close('','_parent','')}
              title={title}
            >
              <div className="tab_icon">
                <Icon name={classIcon} size="lg" />
              </div>
              <p className="tab_title">{getTitleUrl(this.props)}</p>
            </button>
          </div>
        );
      } else {
        if (this.props.url !== 'null' && this.props.url !== '') {
          return (
            <div className="tab_light_unselect" id={this.props.id}>
              <button
                className={classButton}
                onClick={() =>
                  openUrl(
                    this.props.url,
                    this.props.updateRoute,
                    this.props.useBack,
                    this.props.varBack,
                    this.props.varBack1,
					this.props.target
                  )
                }
                title={title}
              >
                <div className="tab_icon">
                  <Icon name={classIcon} size="md" />
                </div>
                <p className="tab_title">{getTitleUrl(this.props)}</p>
              </button>
            </div>
          );
        } else {
          return (
            <div className="tab_light_unselect" id={this.props.id}>
              <button className={classButton} onClick={this.handleClick} title={title}>
                <div className="tab_icon">
                  <Icon name={classIcon} size="md" />
                </div>
                <p className="tab_title">{getTitleUrl(this.props)}</p>
              </button>
            </div>
          );
        }
      }
    }
  }
}

export class TabsButtonAux extends Component<TabspickerButtonProps> {
  constructor(props: any) {
    super(props);
  }

  render() {
    let isDark = config.theme.isDark || false;
	const title = this.props.title || '';
    const classIcon = this.props.icon !== undefined ? this.props.icon : 'refresh';
    const tab_background = (this.props.style === undefined || this.props.style === 'default') ? 'white' : this.props.style;
    const classButton = isDark ? 'tab_unselect tab_face tab_black_disable' : 'tab_unselect tab_face tab_white_disable';
    const classSelected = 'tab_selected tab_face tab_' + tab_background;

    return (
      <div className="tab_aux" id={this.props.id}>
        <button className={classButton} title={title}>
          <div className="tab_icon">
            <Icon name={classIcon} size="lg" />
          </div>
        </button>
      </div>
    );
  }
}

export class GenericButton extends Component<GenericButtonProps> {
  constructor(props: any) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    if (this.props.variable !== '') {
      const queryMap = {
        [`var-${this.props.variable}`]: this.props.value,
      };
      changeUrl(queryMap, this.props.useBack, this.props.varBack, this.props.varBack1);
    }
  }

  render() {
    const actualValue = getNetMonitorVariableValue(this.props.variable);
    const title = this.props.title || '';
	const isDark = config.theme.isDark || false;
    const classIcon = this.props.icon !== undefined ? this.props.icon : 'refresh';
	const buttonStyle = isDark ? 'black_light' : 'black_dark';
	const buttonColor = isDark ? this.props.style + '_dark' : this.props.style + '_light';
    const classButton = this.props.style === 'default' ? 
	  'tab_auxbutton tab_aux_' + buttonStyle + ' tab_radius-4' :
	  'tab_auxbutton tab_aux_' + buttonColor + ' tab_radius-4';
    const classSelected = isDark ? 
	  'tab_button_selected_dark tab_' + this.props.style :
	  'tab_button_selected_light tab_' + this.props.style;

    if (actualValue === this.props.value) {
      return (
        <div className="tab_button" id={this.props.id}>
          <button className={classSelected} title={title}>
            <div className="tab_button_icon">
              <Icon name={classIcon} size="md" />
            </div>
          </button>
        </div>
      );
    } else {
      if (this.props.variable === 'Volver' && this.props.value === 'Volver') {
        return (
          <div className="tab_button" id={this.props.id}>
            <button
              className={
			    isDark ? 'tab_auxbutton tab_aux_white_dark tab_radius-10' : 'tab_auxbutton tab_aux_white_light tab_radius-10'
			  }
              onClick={() => backUrl(this.props.varBack, this.props.varBack1)}
              title={title}
            >
              <div className="tab_icon">
                <Icon name={classIcon} size="lg" />
              </div>
              <p className="tab_title">{getTitleUrl(this.props)}</p>
            </button>
          </div>
        );
      } else if (this.props.variable === 'Cerrar' && this.props.value === 'Cerrar') {
        return (
          <div className="tab_button" id={this.props.id}>
            <button
              className={
			    isDark ? 'tab_auxbutton tab_aux_white_dark tab_radius-10' : 'tab_auxbutton tab_aux_white_light tab_radius-10'
			  }
              onClick={() => window.close('','_parent','')}
              title={title}
            >
              <div className="tab_icon">
                <Icon name={classIcon} size="lg" />
              </div>
              <p className="tab_title">{getTitleUrl(this.props)}</p>
            </button>
          </div>
        );
      } else if (this.props.url !== 'null' && this.props.url !== '') {
        return (
          <div className="tab_button" id={this.props.id}>
            <button
              className={classButton}
              onClick={() =>
                openUrl(
                  this.props.url,
                  this.props.updateRoute,
                  this.props.useBack,
                  this.props.varBack,
                  this.props.varBack1,
				  this.props.target
                )
              }
              title={title}
            >
              <div className={this.props.style === 'default' ? 'tab_button_icon_def' : 'tab_button_icon'}>
                <Icon name={classIcon} size={this.props.style === 'default' ?  '24' : 'md'} />
              </div>
            </button>
          </div>
        );
      } else {
        return (
          <div className="tab_button" id={this.props.id}>
            <button className={classButton} onClick={this.handleClick} title={title}>
              <div className={this.props.style === 'default' ? 'tab_button_icon_def' : 'tab_button_icon'}>
                <Icon name={classIcon} size={this.props.style === 'default' ?  '24' : 'md'} />
              </div>
            </button>
          </div>
        );
      }
    }
  }
}

export class GenericIndicator extends Component<GenericIndicatorProps> {
  constructor(props: any) {
    super(props);
  }

  render() {
    const isDark = config.theme.isDark || false;
    const title = this.props.title || '';
    const classTab = isDark ? 'tab_indicator_dark' : 'tab_indicator_light';
    const classButton = isDark ? 'tab_indicator tab_' + this.props.style : 'tab_indicator tab_' + this.props.style;
    const classSelected = isDark ? 
	  'tab_indicator_selected_dark tab_' + this.props.style :
	  'tab_indicator_selected_light tab_' + this.props.style;

    if (this.props.url !== 'null' && this.props.url !== '') {
      return (
        <div className={classTab} id={this.props.id}>
          <button
            className={classButton}
            onClick={() =>
              openUrl(
                this.props.url,
                this.props.updateRoute,
                this.props.useBack,
                this.props.varBack,
                this.props.varBack1,
				this.props.target
              )
            }
            title={title}
          >
            <p className="indicator_title">{getIndicatorUrl(this.props)}</p>
          </button>
        </div>
      );
    } else {
      return (
        <div className={classTab} id={this.props.id}>
          <button className={classSelected} title={title}>
            <p className="indicator_title">{getIndicatorUrl(this.props)}</p>
          </button>
        </div>
      );
    }
  }
}

export class BackButton extends Component<BackButtonProps> {
  constructor(props: any) {
    super(props);
  }

  render() {
	//const classIcon = this.props.icon !== undefined ? this.props.icon : 'arrow-bar-left';
	const isDark = config.theme.isDark || false;
	if (this.props.variable) {
      if (this.props.variable === 'Volver') {	
		return (
		  <div className={isDark ? 'drop_backButton_dark' : 'drop_backButton_light'} id={this.props.id}>
			<button
			  className="drop_button"
			  onClick={() => backUrl(this.props.varBack, this.props.varBack1)}
			  title={this.props.text}
			>
			  <div className="tab_icon">
				<Icon name={this.props.icon} size="lg" />
			  </div>
			</button>
		  </div>
		);
	  } else if (this.props.variable === 'Cerrar') {
	    return (
		  <div className={isDark ? 'drop_backButton_dark' : 'drop_backButton_light'} id={this.props.id}>
		    <button
			  className="drop_button"
			  onClick={() => window.close('','_parent','')}
			  title={this.props.text}
		    >
			  <div className="tab_icon">
			    <Icon name={this.props.icon} size="lg" />
			  </div>
		    </button>
		  </div>
	    );
	  }
	}
  }
}

function getTitleUrl(props: TabspickerButtonProps): string {
  if (props.text) {
    if (props.text === 'Rotar') {
      return '';
    } else {
      if (props.result !== '') {
        return props.text + ': ' + props.result;
      } else {
        return props.text;
      }
    }
  } else if (props.url) {
    return '';
  }
  return 'Error';
}

function getIndicatorUrl(props: GenericIndicatorProps): string {
  if (props.result) {
    return String(props.result);
  }
  return 'E';
}
