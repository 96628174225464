import { css, cx } from '@emotion/css';
import React, { FC, useState, useEffect  } from 'react'; 
import { NetMonitorTheme2 } from '@grafana/data';
import { locationService } from '@grafana/runtime';
import {
  Icon,
  Field,
  Label,
  Form,
  HorizontalGroup,
  VerticalGroup,
  Input,
  InputControl,
  Select,
  MultiSelect,
  Switch,
  TextArea,
  Collapse,
  useStyles2,
} from '@grafana/ui';
import { CollapsibleSection } from './CollapsibleSection';
import { L1OyMData } from './L1OyMManager';
import { L1OyMExpandedForm } from './L1OyMExpandedForm';
import { MapComponent } from './PointPickerModal';
import { updateImportElements } from './L1OyMTable';
import { valueToType, valueToSubtype, valueToStatus, fiberOpticColors, bufferColors, multiparColors } from './types';
import { mapSelectValueToString } from 'app/features/alerting/unified/utils/amroutes';

export interface L1OyMMapFormProps {
  renderCount: number;
  items: Array<DynamicTableItemProps<T>>;
  onCancel: () => void;
  onImportData: (newElements: [], file: any) => void;
  onCoordinatesChange: (coords: [number, number], currentPlace: string) => void;
  onLineChanges: (coords: [], currentUid: string) => void;
  onPosChange: (center: any, zoom: number) => void;
  onSave: (data: L1OyMData[]) => void;
  onSelect: (uid: string) => void;
  element: L1OyMData;
  isDark: boolean;
  isAdmin: boolean;
  width: number;
  height: number;
  elements: L1OyMData[];
  places: [];
  types: [];
  sites: [];
  pluginVariables: [];
  isKmzAddMode: any;
  zoom: number,
  center: any;
}

export const L1OyMMapForm: FC<L1OyMMapFormProps> = ({ 
  renderCount,
  items,
  onCancel,
  onImportData,
  onCoordinatesChange,
  onLineChanges,
  onPosChange,
  onSave,
  onSelect,
  element, 
  isDark, 
  isAdmin,
  width,
  height,
  elements,
  places,
  types,
  sites,
  pluginVariables,
  isKmzAddMode,
  zoom,
  center
}) => {
  const styles = useStyles2(getStyles(isDark, width, height));
  const pathEvents: [] = [];
  const events = elements.filter(value => value.elementType === 'element');
  const [currentElement, setCurrentElement] = useState(element);
  const [madeChanges, setMadeChanges] = useState(false);

  for (let i = 0; i < events.length; i++) {
	let pathEvent = {
	  label: events[i].title,
	  description: events[i].address,
	  value: events[i].elementId,
	  family: events[i].subType,
	  lat: events[i].coordinates[0] || 0,
	  lng: events[i].coordinates[1] || 0,
	  style: events[i].style,
	};
	pathEvents.push(pathEvent);
  }
  
  const elementsRelations: [] = [];
  for (let i = 0; i < pathEvents.length; i++) {
	const newElement = elements.find(ele => ele.uid === pathEvents[i].value);
	if (newElement) {
	  let markerDidExist = elementsRelations.find(ele => ele.elementId === newElement.uid);
	  if (!markerDidExist) {
	    const relationIds: [] = newElement.relationIds;
	    for (let x = 0; x < relationIds.length; x++) {
	      const parentData = elements.find(ele => ele.uid === relationIds[x]);
		  if (parentData) {
	        if (parentData.coordinates.length > 1) {
		      const newMarker = {
			    elementId: newElement.uid,
			    pathId: newElement.pathId,
			    label: newElement.title,
			    description: newElement.address,
			    family: newElement.subType,
			    value: null,
			    parentId: parentData.elementId,
				lat: null,
			    lng: null,
			    destLat: parseFloat(parentData.coordinates[0]),
			    destLng: parseFloat(parentData.coordinates[1]),
				style: newElement.style,
		      };
			  if (newElement.coordinates.length > 1) {
			    newMarker.lat = parseFloat(newElement.coordinates[0]);
			    newMarker.lng = parseFloat(newElement.coordinates[1]);
			  }
			  elementsRelations.push(newMarker);
			  markerDidExist = true;
			}	
	      } else {
		    const empData = places.find(ele => ele.value === relationIds[x]);
		    if (empData) {
		      const newMarker = {
			    elementId: newElement.uid,
			    pathId: newElement.pathId,
			    label: newElement.title,
			    description: newElement.address,
			    family: 'emp',
			    value: empData.value,
				parentId: empData.value,
			    lat: null,
			    lng: null,
			    destLat: parseFloat(empData.lat),
			    destLng: parseFloat(empData.lng),
				style: empData.style,
		      };
			  if (newElement.coordinates.length > 1) {
			    newMarker.lat = parseFloat(newElement.coordinates[0]);
			    newMarker.lng = parseFloat(newElement.coordinates[1]);
			  }
			  elementsRelations.push(newMarker);
			  markerDidExist = true;
			}
	      }
		}
	  }
	  if (!markerDidExist) {
	    const elementMarker = {
		  elementId: newElement.elementId,
		  pathId: newElement.pathId,
		  label: newElement.title,
		  description: newElement.address,
		  family: newElement.subType,
		  value: newElement.elementId,
		  parentId: null,
		  lat: parseFloat(newElement.coordinates[0]),
		  lng: parseFloat(newElement.coordinates[1]),
		  destLat: parseFloat(newElement.coordinates[0]),
		  destLng: parseFloat(newElement.coordinates[1]),
		  style: newElement.style,
	    };
	    elementsRelations.push(elementMarker);
	  }
	}
  }

  const newItems = items.filter(value => value.data.wasChange === true);
  let ele = currentElement ? currentElement : element;

  return (
	<div className={styles.mapWrapper}>	  
	  <div className={ele ? styles.mapContainerHalf : styles.mapContainerFull}>
		<MapComponent 
		  renderCount={renderCount}
		  currentNodes={[ele]}
		  onImportData={onImportData}
		  onCoordinatesChange={(coords, currentPlace) => {
			if (coords.length === 2 && !isNaN(coords[0]) && !isNaN(coords[1])) {
			  ele.coordinates[0] = parseFloat(coords[0]);
			  ele.coordinates[1] = parseFloat(coords[1]);
			  ele.wasChange = true;
			  setCurrentElement(ele);
			  setMadeChanges(true);
			  onCoordinatesChange(coords, currentPlace);
			}
		  }}
		  onLineChanges={(coords, currentUid) => {
			if (coords.length > 1) {
			  ele.coordinates = coords;
			  ele.wasChange = true;
			  setCurrentElement(ele);
			  setMadeChanges(true);
			  onLineChanges(coords, currentUid);
			}
		  }}
		  onSelectionChange={(currentUid) => {
			if (ele === undefined) {
			  onSelect(currentUid);
			} else if (ele.uid !== currentUid) {
			  onSelect(currentUid);
			}
		  }}
		  onPosChange={onPosChange}
		  markers={isKmzAddMode ? [] : elementsRelations}
		  items={isKmzAddMode ? newItems : items}
		  showMarkerIcons={true}
		  mapSource={pluginVariables[4]}
		  geoVariables={[pluginVariables[5], pluginVariables[6], pluginVariables[7], pluginVariables[8], pluginVariables[9], pluginVariables[1]]}
		  readOnly={false}
		  tableMode={false}
		  isKmzAddMode={isKmzAddMode}
		  zoom={zoom}
		  center={center}
		/>
	  </div>
	  {ele && (
		<div className={styles.expandedContentRow}>
		  <L1OyMExpandedForm
			onCancel={() => {
			  setMadeChanges(false);
			  setCurrentElement(undefined);
			  onCancel();
			}}
			onSave={(item, typeOfUpdate) => {
			  onSave(item, typeOfUpdate);
			}}
			element={ele}
			isDark={isDark}
			isAdmin={isAdmin}
			width={width}
			elements={elements}
			places={places}
			sites={sites}
			types={types}
			pluginVariables={pluginVariables}
			tableMode={false}
			setChanges={madeChanges}
		  />
		</div>
	  )}
	</div>
  );
};

const getStyles = (isDark: boolean, width: number, height: number) => {
  const okButtonColor = isDark ? '#557FFF' : '#6C63FE';
  const buttonBorder = isDark ? '#44444c' : '#9DA5B8';
  return (theme: NetMonitorTheme2) => ({
    mapWrapper: css`
	  display: inline-flex;
      flex-flow: wrap;
      padding: 5px;
      align-content: flex-start;
      justify-content: space-between;
	  width: 100%
    `,
	mapContainerHalf: css`
	  width: ${width * 0.47}px;
	  height: ${height * 0.9}px;
      margin-top: 5px;
      margin-left: 10px;
	  border: 1px solid ${buttonBorder};
      border-radius: 5px;
	  margin-right: 15px;
    `,
	mapContainerFull: css`
	  width: ${width * 0.98}px;
	  min-width: 250px;
	  height: ${height * 0.9}px;
      margin-top: 5px;
      margin-left: -5px;
	  border: 1px solid ${buttonBorder};
      border-radius: 5px;
    `,
    expandedContentRow: css`
	  padding: 0 ${theme.spacing(1)} 0 ${theme.spacing(3)};
      position: relative;
	  transition: width 0.5s ease, margin-left 0.5s ease;

      ${theme.breakpoints.down('sm')} {
        grid-column-start: 2;
        border-top: 1px solid ${theme.colors.border.strong};
        grid-row: auto;
        padding: ${theme.spacing(1)} 0 0 0;
      }
    `,
    add_icon: css`
      border: none;
      min-width: 24px;
	  margin-right: 5px;
	  margin-top: -10px;
	  color: ${okButtonColor};
    `,
    del_icon: css`
      border: none;
      min-width: 24px;
	  margin-right: 5px;
    `,
  });
};
