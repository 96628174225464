import { config } from '@grafana/runtime';

const isDark = config.theme.isDark;
var iconColorInUse = '#000000';

const iconColor = {
	man_hole: { color: isDark ? '#D9AF27' : '#FF7F27' },
	hand_hole: { color:  isDark ? '#5ECD6B' : '#FFC530' },
	odf: { color: isDark ? '#58A956' : '#24AB00' },
	foClosure: { color: isDark ? '#64A673' : '#5A6171' },
	current: { color: isDark ? '#44444C' : '#23282E' },
	text: { color: isDark ? '#EFF4FA' : '#23282E' },
	patchPanel: { color: isDark ? '#557FFF' : '#6C63FE' },
	terminationBox: { color: isDark ? '#F74545' : '#FB3333' },
	distributionBox: { color: isDark ? '#F74545' : '#FB3333' },
	plc: { color: isDark ? '#936BFF' : '#5D2BE9' },
	fbt: { color: isDark ? '#557FFF' : '#6C63FE' },
	link: { color: isDark ? '#58A956' : '#24AB00' },
	path: { color: isDark ? '#58A956' : '#24AB00' },
	emp: { color: isDark ? '#936BFF' : '#5D2BE9' },
}

export const systemIcons = (color:string, size: number, name: string = 'defaultIcon') => {
  const defaultColor = iconColor[name] ? iconColor[name].color : iconColorInUse;
  iconColorInUse = color && color !== '' ? color : defaultColor;
  let iconHtml = defaultIcons[name] ? defaultIcons[name].html : defaultIcons['defaultIcon'].html;
  iconHtml = iconHtml.replaceAll('iconColorInUse', iconColorInUse);
  let icon = L.divIcon({
    className: '',
    html: iconHtml,
    iconSize: [24, 24],
    iconAnchor: [12, 12],
    popupAnchor: [24, -26],
  });

  if (size) {
    icon.options.iconSize = size > 8 && size < 48 ? [size, size] : [24, 24];
    icon.options.iconAnchor = size > 8 && size < 48 ? [(size / 2), (size / 2)] : [12, 12];
    icon.options.popupAnchor = size > 8 && size < 48 ? [(size), -(size - 2)] : [24, -26];
  }
  if (name === 'emp') {
	icon.options.iconAnchor = size > 8 && size < 48 ? [(size / 2), size] : [12, 24];
  }
  return icon;
};

const defaultIcons = {
    defaultIcon: {
      html: `
		<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.25003 10C7.25003 7.37665 9.37667 5.25 12 5.25C14.6234 5.25 16.75 7.37665 16.75 10C16.75 12.6234 14.6234 14.75 12 14.75C9.37667 14.75 7.25003 12.6234 7.25003 10ZM12 6.75C10.2051 6.75 8.75003 8.20507 8.75003 10C8.75003 11.7949 10.2051 13.25 12 13.25C13.795 13.25 15.25 11.7949 15.25 10C15.25 8.20507 13.795 6.75 12 6.75Z" fill="iconColorInUse"/>
		  <path fill-rule="evenodd" clip-rule="evenodd" d="M3.52439 8.85685C3.87872 4.55824 7.47087 1.25 11.7841 1.25H12.216C16.5292 1.25 20.1213 4.55824 20.4757 8.85685C20.666 11.166 19.9527 13.4589 18.4861 15.2526L13.693 21.1144C12.818 22.1845 11.1821 22.1845 10.307 21.1144L5.51399 15.2526C4.04733 13.4589 3.33405 11.166 3.52439 8.85685ZM11.7841 2.75C8.25152 2.75 5.30952 5.45948 5.01932 8.98008C4.8609 10.9019 5.45455 12.8102 6.67521 14.3031L11.4683 20.1649C11.7431 20.501 12.2569 20.501 12.5318 20.1649L17.3248 14.3031C18.5455 12.8102 19.1391 10.9019 18.9807 8.98008C18.6905 5.45948 15.7485 2.75 12.216 2.75H11.7841Z" fill="iconColorInUse"/>
		</svg>
      `
    },
	current: {
      html: `
		<svg viewBox="0 0 24 24" fill="#FFFFFFA0" xmlns="http://www.w3.org/2000/svg">
		  <circle cx="12" cy="12" r="8" fill="#F4F6F9"/>
		  <path d="M12 8.25C9.92893 8.25 8.25 9.92893 8.25 12C8.25 14.0711 9.92893 15.75 12 15.75C14.0711 15.75 15.75 14.0711 15.75 12C15.75 9.92893 14.0711 8.25 12 8.25Z" fill="iconColorInUse"/>
		  <path fill-rule="evenodd" clip-rule="evenodd" d="M12 1.25C12.4142 1.25 12.75 1.58579 12.75 2V3.28169C16.9842 3.64113 20.3589 7.01581 20.7183 11.25H22C22.4142 11.25 22.75 11.5858 22.75 12C22.75 12.4142 22.4142 12.75 22 12.75H20.7183C20.3589 16.9842 16.9842 20.3589 12.75 20.7183V22C12.75 22.4142 12.4142 22.75 12 22.75C11.5858 22.75 11.25 22.4142 11.25 22V20.7183C7.01581 20.3589 3.64113 16.9842 3.28169 12.75H2C1.58579 12.75 1.25 12.4142 1.25 12C1.25 11.5858 1.58579 11.25 2 11.25H3.28169C3.64113 7.01581 7.01581 3.64113 11.25 3.28169V2C11.25 1.58579 11.5858 1.25 12 1.25ZM4.75 12C4.75 16.0041 7.99594 19.25 12 19.25C16.0041 19.25 19.25 16.0041 19.25 12C19.25 7.99594 16.0041 4.75 12 4.75C7.99594 4.75 4.75 7.99594 4.75 12Z" fill="iconColorInUse"/>
		</svg>
      `
    },
    man_hole: {
      html: `
		<svg viewBox="0 0 24 24" fill="iconColorInUse" xmlns="http://www.w3.org/2000/svg">
		  <path d="M5.28033 14.341C4.57585 13.5082 4.22361 13.0918 4.08901 12.627C3.97033 12.2172 3.97033 11.7828 4.08901 11.373C4.22361 10.9082 4.57585 10.4918 5.28032 9.65904L9.1484 5.08645C10.1277 3.9288 10.6173 3.34997 11.2036 3.13889C11.7179 2.9537 12.2821 2.9537 12.7964 3.13889C13.3827 3.34997 13.8723 3.9288 14.8516 5.08645L18.7197 9.65904C19.4242 10.4918 19.7764 10.9082 19.911 11.373C20.0297 11.7828 20.0297 12.2172 19.911 12.627C19.7764 13.0918 19.4242 13.5082 18.7197 14.341L14.8516 18.9135C13.8723 20.0712 13.3827 20.65 12.7964 20.8611C12.2821 21.0463 11.7179 21.0463 11.2036 20.8611C10.6173 20.65 10.1277 20.0712 9.1484 18.9135L5.28033 14.341Z" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
		</svg>
      `
    },
    hand_hole: {
      html: `
		<svg viewBox="0 0 24 24" fill="iconColorInUse" xmlns="http://www.w3.org/2000/svg">
		  <rect x="3" y="5" width="18" height="14" rx="2" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
		</svg>
      `
    },
    fo_closure: {
      html: `
		<svg viewBox="0 0 24 24" fill="iconColorInUse" xmlns="http://www.w3.org/2000/svg">
		  <path d="M5 6.2C5 5.07989 5 4.51984 5.21799 4.09202C5.40973 3.71569 5.71569 3.40973 6.09202 3.21799C6.51984 3 7.0799 3 8.2 3H15.8C16.9201 3 17.4802 3 17.908 3.21799C18.2843 3.40973 18.5903 3.71569 18.782 4.09202C19 4.51984 19 5.0799 19 6.2V17.8C19 18.9201 19 19.4802 18.782 19.908C18.5903 20.2843 18.2843 20.5903 17.908 20.782C17.4802 21 16.9201 21 15.8 21H8.2C7.07989 21 6.51984 21 6.09202 20.782C5.71569 20.5903 5.40973 20.2843 5.21799 19.908C5 19.4802 5 18.9201 5 17.8V6.2Z" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
		</svg>
      `
    },
    odf: {
      html: `
		<svg viewBox="0 0 24 24" fill="iconColorInUse" xmlns="http://www.w3.org/2000/svg">
		  <path d="M8 12H8.01M12 12H12.01M16 12H16.01M7.2 20H16.8C17.9201 20 18.4802 20 18.908 19.782C19.2843 19.5903 19.5903 19.2843 19.782 18.908C20 18.4802 20 17.9201 20 16.8V7.2C20 6.0799 20 5.51984 19.782 5.09202C19.5903 4.71569 19.2843 4.40973 18.908 4.21799C18.4802 4 17.9201 4 16.8 4H7.2C6.0799 4 5.51984 4 5.09202 4.21799C4.71569 4.40973 4.40973 4.71569 4.21799 5.09202C4 5.51984 4 6.07989 4 7.2V16.8C4 17.9201 4 18.4802 4.21799 18.908C4.40973 19.2843 4.71569 19.5903 5.09202 19.782C5.51984 20 6.07989 20 7.2 20Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
		</svg>
      `
    },
	termination_box: {
      html: `
		<svg viewBox="0 0 24 24" fill="iconColorInUse" xmlns="http://www.w3.org/2000/svg">
		  <path d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
		</svg>
      `
    },
	distribution_box: {
      html: `
		<svg viewBox="0 0 24 24" fill="iconColorInUse" xmlns="http://www.w3.org/2000/svg">
		  <rect x="4" y="4" width="16" height="16" rx="2" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
		</svg>
      `
    },
    patch_panel: {
      html: `
		<svg viewBox="0 0 24 24" fill="iconColorInUse" xmlns="http://www.w3.org/2000/svg">
		  <path d="M7 9V15M11 12H11.01M14 12H14.01M17 12H17.01M4.6 15H19.4C19.9601 15 20.2401 15 20.454 14.891C20.6422 14.7951 20.7951 14.6422 20.891 14.454C21 14.2401 21 13.9601 21 13.4V10.6C21 10.0399 21 9.75992 20.891 9.54601C20.7951 9.35785 20.6422 9.20487 20.454 9.10899C20.2401 9 19.9601 9 19.4 9H4.6C4.03995 9 3.75992 9 3.54601 9.10899C3.35785 9.20487 3.20487 9.35785 3.10899 9.54601C3 9.75992 3 10.0399 3 10.6V13.4C3 13.9601 3 14.2401 3.10899 14.454C3.20487 14.6422 3.35785 14.7951 3.54601 14.891C3.75992 15 4.03995 15 4.6 15Z" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
		</svg>
      `
    },
    plc: {
      html: `
		<svg viewBox="0 0 24 24" fill="iconColorInUse" xmlns="http://www.w3.org/2000/svg">
		  <g id="Triangle">
		    <path id="Vector" d="M4.37891 15.1999C3.46947 16.775 3.01489 17.5634 3.08281 18.2097C3.14206 18.7734 3.43792 19.2851 3.89648 19.6182C4.42204 20.0001 5.3309 20.0001 7.14853 20.0001H16.8515C18.6691 20.0001 19.5778 20.0001 20.1034 19.6182C20.5619 19.2851 20.8579 18.7734 20.9172 18.2097C20.9851 17.5634 20.5307 16.775 19.6212 15.1999L14.7715 6.79986C13.8621 5.22468 13.4071 4.43722 12.8135 4.17291C12.2957 3.94236 11.704 3.94236 11.1862 4.17291C10.5928 4.43711 10.1381 5.22458 9.22946 6.79845L4.37891 15.1999Z" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
		  </g>
		</svg>
      `
    },
    fbt: {
      html: `
		<svg viewBox="0 0 24 24" fill="iconColorInUse" xmlns="http://www.w3.org/2000/svg">
		  <g id="Triangle">
		    <path d="M4.37891 15.1999C3.46947 16.775 3.01489 17.5634 3.08281 18.2097C3.14206 18.7734 3.43792 19.2851 3.89648 19.6182C4.42204 20.0001 5.3309 20.0001 7.14853 20.0001H16.8515C18.6691 20.0001 19.5778 20.0001 20.1034 19.6182C20.5619 19.2851 20.8579 18.7734 20.9172 18.2097C20.9851 17.5634 20.5307 16.775 19.6212 15.1999L14.7715 6.79986C13.8621 5.22468 13.4071 4.43722 12.8135 4.17291C12.2957 3.94236 11.704 3.94236 11.1862 4.17291C10.5928 4.43711 10.1381 5.22458 9.22946 6.79845L4.37891 15.1999Z" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
		  </g>
		</svg>
      `
    },
    home: {
      html: `
		<svg viewBox="0 0 24 24" fill="iconColorInUse" xmlns="http://www.w3.org/2000/svg">
		  <path d="M14 21.0001V15.0001H10V21.0001M19 9.77818V16.2001C19 17.8802 19 18.7203 18.673 19.362C18.3854 19.9265 17.9265 20.3855 17.362 20.6731C16.7202 21.0001 15.8802 21.0001 14.2 21.0001H9.8C8.11984 21.0001 7.27976 21.0001 6.63803 20.6731C6.07354 20.3855 5.6146 19.9265 5.32698 19.362C5 18.7203 5 17.8802 5 16.2001V9.77753M21 12.0001L15.5668 5.96405C14.3311 4.59129 13.7133 3.9049 12.9856 3.65151C12.3466 3.42894 11.651 3.42899 11.0119 3.65165C10.2843 3.90516 9.66661 4.59163 8.43114 5.96458L3 12.0001" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
		</svg>
      `
    },
	link: {
      html: `
		<svg viewBox="0 0 24 24" fill="iconColorInUse" xmlns="http://www.w3.org/2000/svg">
		  <path d="M8.18009 16.0199C7.42009 15.9499 6.6701 15.5999 6.0901 14.9899C4.7701 13.5999 4.7701 11.3199 6.0901 9.92989L8.2801 7.6299C9.6001 6.2399 11.7701 6.2399 13.1001 7.6299C14.4201 9.0199 14.4201 11.2999 13.1001 12.6899L12.0101 13.8399" stroke="iconColorInUse" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
		  <path d="M15.8202 7.97998C16.5802 8.04998 17.3302 8.39998 17.9102 9.00998C19.2302 10.4 19.2302 12.68 17.9102 14.07L15.7202 16.37C14.4002 17.76 12.2302 17.76 10.9002 16.37C9.58016 14.98 9.58016 12.7 10.9002 11.31L11.9902 10.16" stroke="iconColorInUse" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
		  <path d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"/>
		</svg>
      `
    },
	path: {
      html: `
		<svg viewBox="0 0 24 24" fill="iconColorInUse" xmlns="http://www.w3.org/2000/svg">
		  <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9426 1.25H12.0574C14.3658 1.24999 16.1748 1.24998 17.5863 1.43975C19.031 1.63399 20.1711 2.03933 21.0659 2.93414C21.9607 3.82895 22.366 4.96897 22.5603 6.41371C22.75 7.82519 22.75 9.63423 22.75 11.9426V12.0574C22.75 14.3658 22.75 16.1748 22.5603 17.5863C22.366 19.031 21.9607 20.1711 21.0659 21.0659C20.1711 21.9607 19.031 22.366 17.5863 22.5603C16.1748 22.75 14.3658 22.75 12.0574 22.75H11.9426C9.63423 22.75 7.82519 22.75 6.41371 22.5603C4.96897 22.366 3.82895 21.9607 2.93414 21.0659C2.03933 20.1711 1.63399 19.031 1.43975 17.5863C1.24998 16.1748 1.24999 14.3658 1.25 12.0574V11.9426C1.24999 9.63423 1.24998 7.82519 1.43975 6.41371C1.63399 4.96897 2.03933 3.82895 2.93414 2.93414C3.82895 2.03933 4.96897 1.63399 6.41371 1.43975C7.82519 1.24998 9.63423 1.24999 11.9426 1.25ZM6.61358 2.92637C5.33517 3.09825 4.56445 3.42514 3.9948 3.9948C3.42514 4.56445 3.09825 5.33517 2.92637 6.61358C2.75159 7.91356 2.75 9.62178 2.75 12C2.75 14.3782 2.75159 16.0864 2.92637 17.3864C3.09825 18.6648 3.42514 19.4355 3.9948 20.0052C4.56445 20.5749 5.33517 20.9018 6.61358 21.0736C7.91356 21.2484 9.62178 21.25 12 21.25C14.3782 21.25 16.0864 21.2484 17.3864 21.0736C18.6648 20.9018 19.4355 20.5749 20.0052 20.0052C20.5749 19.4355 20.9018 18.6648 21.0736 17.3864C21.2484 16.0864 21.25 14.3782 21.25 12C21.25 9.62178 21.2484 7.91356 21.0736 6.61358C20.9018 5.33517 20.5749 4.56445 20.0052 3.9948C19.4355 3.42514 18.6648 3.09825 17.3864 2.92637C16.0864 2.75159 14.3782 2.75 12 2.75C9.62178 2.75 7.91356 2.75159 6.61358 2.92637Z" fill="iconColorInUse"/>
		  <path fill-rule="evenodd" clip-rule="evenodd" d="M12 5.75C12.4142 5.75 12.75 6.08579 12.75 6.5V12.5C12.75 14.2949 14.2051 15.75 16 15.75H16.1893L15.9697 15.5303C15.6768 15.2374 15.6768 14.7626 15.9697 14.4697C16.2626 14.1768 16.7374 14.1768 17.0303 14.4697L18.5303 15.9697C18.8232 16.2626 18.8232 16.7374 18.5303 17.0303L17.0303 18.5303C16.7374 18.8232 16.2626 18.8232 15.9697 18.5303C15.6768 18.2374 15.6768 17.7626 15.9697 17.4697L16.1893 17.25H16C14.3205 17.25 12.8446 16.3784 12 15.0628C11.1554 16.3784 9.67947 17.25 8 17.25H7.81066L8.03033 17.4697C8.32322 17.7626 8.32322 18.2374 8.03033 18.5303C7.73744 18.8232 7.26256 18.8232 6.96967 18.5303L5.46967 17.0303C5.17678 16.7374 5.17678 16.2626 5.46967 15.9697L6.96967 14.4697C7.26256 14.1768 7.73744 14.1768 8.03033 14.4697C8.32322 14.7626 8.32322 15.2374 8.03033 15.5303L7.81066 15.75H8C9.79493 15.75 11.25 14.2949 11.25 12.5V6.5C11.25 6.08579 11.5858 5.75 12 5.75Z" fill="iconColorInUse"/>
		</svg>
      `
    },
	emp: {
      html: `
		<svg viewBox="0 0 24 24" fill="iconColorInUse" stroke="#000000" stroke-width="1" xmlns="http://www.w3.org/2000/svg">
		  <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z" />
		</svg>
      `
    },
  };
