import { FieldSelectEditor } from './FieldSelectEditor';
import { PanelPlugin } from '@grafana/data';
import { SimpleOptions } from './types';
import { TabPanel } from './TabPanel';

export const plugin = new PanelPlugin<SimpleOptions>(TabPanel).setPanelOptions(builder => {
  return builder
    .addCustomEditor({
      id: 'buttonTypeOption',
      path: 'buttonTypeOption',
      name: 'Tab Type',
      description: 'Type of tab: Tab, Button, Indicator',
      defaultValue: 'tab_type',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'buttonStyleOption',
      path: 'buttonStyleOption',
      name: 'Tab Style Field',
      description: 'The tab/button color: White, Grey, Black, Red, Yellow, Orange, Green and Blue',
      defaultValue: 'tab_style',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'buttonIconOption',
      path: 'buttonIconOption',
      name: 'Tab Icon Field',
      description: 'The field that will be used as the Icon for the tab',
      defaultValue: 'tab_icon',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'buttonTextOption',
      path: 'buttonTextOption',
      name: 'Tab Text Field',
      description: 'The field that will be used as the Text for the tab',
      defaultValue: 'tab_text',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'buttonVariableOption',
      path: 'buttonVariableOption',
      name: 'Tab Variable Field',
      description: 'The variable modified by the tab. This filed is used only if no url link was defined.',
      defaultValue: 'tab_variable',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'buttonValueOption',
      path: 'buttonValueOption',
      name: 'Tab Value Field',
      description: 'The new value to be asigned. This filed is used only if no url link was defined.',
      defaultValue: 'tab_value',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'buttonResultOption',
      path: 'buttonResultOption',
      name: 'Tab Result Field',
      description: 'The field that will be used as the indicator inside the tab',
      defaultValue: 'tab_result',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'buttonUrlOption',
      path: 'buttonUrlOption',
      name: 'Tab URL link',
      description: 'The field that will be used as the url for the tab. This field has precedence over variable.',
      defaultValue: 'tab_url',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'buttonTargetOption',
      path: 'buttonTargetOption',
      name: 'Tab URL target',
      description: 'The field that will be used as the url target for the tab.',
      defaultValue: 'tab_target',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'buttonUpdateUrlRoute',
      path: 'buttonUpdateUrlRoute',
      name: 'The tab update the url route?',
      description: 'The field indicate if must be update the path route record.',
      defaultValue: 'update_route',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })
    .addCustomEditor({
      id: 'buttonTitleOption',
      path: 'buttonTitleOption',
      name: 'Tab Tooltip Field',
      description: 'The tooltip shows with the tab',
      defaultValue: 'tab_title',
      category: ['Data Mapping'],
      editor: FieldSelectEditor,
    })

    .addNumberInput({
      path: 'minWidth',
      name: 'Plugin min width',
      description: 'Hide content when panel width cross below this threshold',
      category: ['General Settings'],
	  settings: {
        placeholder: '250',
        min: 200,
        max: 500,
      },
      defaultValue: '250',
    })
    .addSelect({
      path: 'displayStyle',
      name: 'Style',
      description: 'Visual presentation',
      category: ['General Settings'],
      defaultValue: 'button',
      settings: {
        options: [
          {
            value: 'tab',
            label: 'Tab/Button/Indicator',
          },
          {
            value: 'dropdown',
            label: 'Dropdown',
          },
        ],
      },
    })
    .addNumberInput({
      path: 'tabMinWidth',
      name: 'Tabs min width separation',
      category: ['General Settings'],
      settings: {
        placeholder: '50',
        min: 20,
        max: 200,
      },
      defaultValue: 50,
      showIf: config => config.displayStyle === 'tab',
    })
    .addBooleanSwitch({
      path: 'backButton',
      name: 'Back Button',
      description: 'If it is active, shows back tab',
      category: ['General Settings'],
      defaultValue: false,
      showIf: config => config.displayStyle === 'tab' && config.closeButton === false,
    })
    .addBooleanSwitch({
      path: 'closeButton',
      name: 'Close Button',
      description: 'If it is active, shows close tab',
      category: ['General Settings'],
      defaultValue: false,
      showIf: config => config.displayStyle === 'tab' && config.backButton === false,
    })
    .addTextInput({
      path: 'backButtonText',
      name: 'Back Button Text',
      description: 'Back button text',
      category: ['General Settings'],
      defaultValue: 'Volver',
      showIf: config => config.backButton === true,
    })
    .addBooleanSwitch({
      path: 'useVarBackVariables',
      name: 'Generate Back Variable',
      description: 'If it is active, the plugin generate current and previous URL',
      category: ['General Settings'],
      defaultValue: false,
    })
    .addTextInput({
      path: 'varBackVariable',
      name: 'current URL Variable',
      description: 'The name of variable which represent current URL',
      category: ['General Settings'],
      defaultValue: '',
      showIf: config => config.useVarBackVariables === true,
    })
    .addTextInput({
      path: 'varBack1Variable',
      name: 'previous URL Variable',
      description: 'The name of variable which represent previous URL',
      category: ['General Settings'],
      defaultValue: '',
      showIf: config => config.useVarBackVariables === true,
    })
    .addTextInput({
      path: 'backButtonUrl',
      name: 'Back URL',
      description: 'link to open when press the Back Tab',
      category: ['General Settings'],
      defaultValue: '#',
      showIf: config => config.backButton === true && config.useVarBackVariables === false,
    })
    .addBooleanSwitch({
      path: 'rotateButton',
      name: 'Rotate Tab',
      description: 'If it is active, shows Rotate icon',
      category: ['General Settings'],
      defaultValue: false,
      showIf: config => config.displayStyle === 'tab',
    })
    .addTextInput({
      path: 'rotateButtonUrl',
      name: 'Rotate URL',
      description: 'URL to use in rotate action',
      category: ['General Settings'],
      defaultValue: '',
      showIf: config => config.rotateButton === true,
    })
    .addSelect({
      path: 'backButtonStyle',
      name: 'Back and Rotate button Style',
      description:
        'Style to use in the Back or Rotate Tab. Theme style uses system default.',
      category: ['General Settings'],
      defaultValue: 'default',
      showIf: config => config.rotateButton === true || config.backButton === true,
      settings: {
        options: [
          {
            value: 'default',
            label: 'Theme',
          },
          {
            value: 'grey',
            label: 'Grey',
          },
          {
            value: 'red',
            label: 'Red',
          },
          {
            value: 'yellow',
            label: 'Yellow',
          },
          {
            value: 'orange',
            label: 'Orange',
          },
          {
            value: 'green',
            label: 'Green',
          },
          {
            value: 'blue',
            label: 'Blue',
          },
        ],
      },
    })
    .addTextInput({
      path: 'defaultVariable',
      name: 'Variable name',
      description: 'Variable name to use as default',
      category: ['General Settings'],
      defaultValue: '',
      showIf: config => config.displayStyle === 'dropdown',
    })
    .addTextInput({
      path: 'defaultValue',
      name: 'Default variable value',
      description: 'Variable value to use in clean action',
      category: ['General Settings'],
      defaultValue: '',
      showIf: config => config.rotateButton === true,
    })
    .addTextInput({
      path: 'defaultPrimary',
      name: 'Default primary tab',
      description: 'indicate the tab number use as primary',
      category: ['General Settings'],
      defaultValue: '0',
    })

    .addTextInput({
      path: 'error1',
      name: 'Query error message',
      category: ['Error Messages'],
      defaultValue: 'Error al obtener datos',
    })
    .addTextInput({
      path: 'error2',
      name: 'On null data message',
      category: ['Error Messages'],
      defaultValue: 'Datos nulos',
    })
    .addTextInput({
      path: 'error3',
      name: 'On plugin load error message',
      category: ['Error Messages'],
      defaultValue: 'Error al cargar el complemento',
    })
    .addTextInput({
      path: '   ',
      name: 'Size error message',
      category: ['Error Messages'],
      defaultValue: 'Error: contenedor demasiado pequeño',
    });
});
