import React, { useState, useRef, useEffect, useMemo } from 'react';
import { PanelProps, GraphSeriesValue } from '@grafana/data';
import { Icon } from '@grafana/ui';
import { config } from '@grafana/runtime';
import { TabsData, GenericButtonData, GenericIndicatorData, SimpleOptions } from 'types';
import { TabsSelect } from './TabsSelect';
import { TabsButton, TabsButtonAux, GenericButton, GenericIndicator, BackButton } from './TabsButton';

interface TabPanelProps extends PanelProps<SimpleOptions> {}

export const TabPanel: React.FC<TabPanelProps> = React.memo(({ options, data, width, height, replaceVariables }) => {
  const [initialCalculationsDone, setInitialCalculationsDone] = useState(false);
  const renderCount = useRef(0);
  const [error1, setError1] = useState('');
  const [error2, setError2] = useState('');
  const [error3, setError3] = useState('');
  const [error4, setError4] = useState('');
  const [initialTab, setInitialTab] = useState<Number>(0);
  const [primaryButton, setPrimaryButton] = useState<Number>(0);
  const [varBack, setVarBack] = useState('null');
  const [varBack1, setVarBack1] = useState('null');
  const useBack = options.useVarBackVariables || options.closeButton ? true : false;

  useEffect(() => {
    renderCount.current = renderCount.current + 1;
	if (!initialCalculationsDone) {
	  setError1(replaceVariables(options.error1));
      setError2(replaceVariables(options.error2));
      setError3(replaceVariables(options.error3));
      setError4(replaceVariables(options.error4));
	  const primary = replaceVariables(options.defaultPrimary)
	  setPrimaryButton(isNaN(primary) ? 0 : Number(primary));
	  if (useBack && !options.closeButton) {
		setVarBack(replaceVariables(options.varBackVariable));
		setVarBack1(replaceVariables(options.varBack1Variable));
	  }
	  setInitialCalculationsDone(true);
	}
  }, [options, replaceVariables]);

  if (width < options.minWidth) {
    return (
	  <div className="timeoptionsErrorContainer" title={error4}>
	    <Icon name={'cloud-slash'} size="xl" />
	  </div>
	);
  }
  if (data.state === 'Error' && !options.closeButton && !options.backButton) {
    return (
	  <div className="timeoptionsErrorContainer" title={error1}>
	    <Icon name={'sync-slash'} size="xl" />
	  </div>
	);
  }
  if (data.series[0].length < 1 && !options.closeButton && !options.backButton) {
    return (
	  <div className="timeoptionsErrorContainer" title={error2}>
	    <Icon name={'image-slash'} size="xl" />
	  </div>
	);
  }

  const font = '13px times new roman';
  const isDark = config.theme.isDark || false;
  const drop_container = isDark ? 'dropdown__container_dark' : 'dropdown__container_light';
  const separador_v = isDark ? 'separadorV_dark' : 'separadorV_light';
  const separador_h = isDark ? 'separadorH_dark' : 'separadorH_light';
  const tabModeEnable = (options.displayStyle === 'dropdown' || width < 500) ? false : true;

  var tabsCount = 0;
  var tabsWindow = -1;
  var finalTab = 0;
  var showLeftTab = false;
  var showRightTab = false;
  var tabsWidth = 30;

  const maxTabContainerWidth = useMemo(() => useBack ? (width * 0.92) - 105 : (width * 0.92) - 40);
  const generateTabs = useMemo(() => {
    const rightAuxButton: GenericButtonData[] = [];
    const buttons: TabsData[] = [];
    const backAuxTab: TabsData[] = [];
    const backAuxButton: GenericButtonData[] = [];
    const leftAuxButton: GenericButtonData[] = [];
    const buttons2: GenericButtonData[] = [];
    const buttons3: GenericIndicatorData[] = [];
    if (!initialCalculationsDone) {
	  return { leftAuxButton, rightAuxButton, buttons, buttons2, buttons3, backAuxTab, backAuxButton };
	}
    data.series.forEach(series => {
      const tabsVals: GraphSeriesValue[] = series.fields[0].values.toArray();
      finalTab = tabsVals.length;
      for (let i = 0; i < tabsVals.length; i++) {
        let elementType = series.fields.find(field => field.name === options.buttonTypeOption)?.values.get(i);
        let elementText = series.fields.find(field => field.name === options.buttonTextOption)?.values.get(i);
        if (elementType === 'tab') {
          let canvas = document.createElement('canvas');
          let context = canvas.getContext('2d');
          context.font = font;
          let newTabWidth = context.measureText(elementText).width * 1.5;
          tabsWidth = tabsWidth + Math.ceil(newTabWidth) + 30;
		  tabsCount = tabsCount + 1;
        } else if (elementType === 'button') {
          tabsWidth = tabsWidth + 30;
        } else {
		  let canvas = document.createElement('canvas');
          let context = canvas.getContext('2d');
          context.font = font;
          let newTabWidth = context.measureText(elementText).width * 1.5;
          tabsWidth = tabsWidth + Math.ceil(newTabWidth) + 15;
        }
        if (tabsWidth < maxTabContainerWidth && elementType === 'tab') {
          tabsWindow = tabsWindow + 1;
        }
      }
      if (tabsWidth > maxTabContainerWidth && tabsCount > tabsWindow) {
        finalTab = initialTab + tabsWindow;
        if (finalTab <= tabsCount) {
          tabsWindow = tabsWindow - 1;
          finalTab = finalTab - 1;
          showRightTab = true;
          if (initialTab > 0) {
            showLeftTab = true;
          }
        } else {
          if (initialTab > 0) {
            tabsWindow = tabsWindow - 1;
            finalTab = finalTab - 1; 
            showLeftTab = true;
          }
        }
      }
      if (tabModeEnable && showLeftTab) {
        let left_tab: TabsData = {
          text: '...',
          variable: '',
          value: 'Left',
          icon: 'angle-double-left',
          url: '',
          title: 'Ver ' + String(initialTab) + ' pestañas más',
          style: '',
		  target: '_self',
          useBack: false,
          varBack: 'null',
          varBack1: 'null',
        };
        leftAuxButton.push(left_tab);
      }
      if (tabModeEnable && showRightTab) {
        let right_tab: TabsData = {
          text: '...',
          variable: '',
          value: 'Rigth',
          icon: 'angle-double-right',
          url: '',
          title: 'Ver ' + String(tabsCount - finalTab) + ' pestañas más',
          style: '',
		  target: '_self',
          useBack: false,
          varBack: 'null',
          varBack1: 'null',
        };
        rightAuxButton.push(right_tab);
      }
      for (let i = -1; i < tabsVals.length; i++) {
        if (options.displayStyle === 'dropdown' && i < 0) {
          let aux_button: TabsData = {
            id: 0,
            text: options.defaultValue || 'Todos',
            variable: options.defaultVariable,
            value: options.defaultValue || 'Todos',
            result: '',
            style: options.backButtonStyle || 'default',
            title: 'Ver Todos',
            url: 'null',
            icon: 'refresh',
			target: '_self',
            useBack: false,
            varBack: 'null',
            varBack1: 'null',
          };
          buttons.push(aux_button);
        } else {
          let type = series.fields.find(field => field.name === options.buttonTypeOption)?.values.get(i);
          if (typeof type !== 'undefined' && type === 'tab') {
            let tab: TabsData = {
              text: series.fields.find(field => field.name === options.buttonTextOption)?.values.get(i),
              variable: series.fields.find(field => field.name === options.buttonVariableOption)?.values.get(i),
              value: series.fields.find(field => field.name === options.buttonValueOption)?.values.get(i),
              result: series.fields.find(field => field.name === options.buttonResultOption)?.values.get(i),
              style: series.fields.find(field => field.name === options.buttonStyleOption)?.values.get(i),
              title: series.fields.find(field => field.name === options.buttonTitleOption)?.values.get(i),
              url: series.fields.find(field => field.name === options.buttonUrlOption)?.values.get(i),
              updateRoute: series.fields.find(field => field.name === options.buttonUpdateUrlRoute)?.values.get(i),
              icon: series.fields.find(field => field.name === options.buttonIconOption)?.values.get(i),
              target: series.fields.find(field => field.name === options.buttonTargetOption)?.values.get(i) || '_self',
			  useBack: false,
              varBack: 'null',
              varBack1: 'null',
            };
			let primarySelect = Number(tab.value);
			if (primarySelect === NaN) {
			  if (primaryButton === i) {
				tab.primary = true;
			  }
			} else if (primaryButton === primarySelect) {
              tab.primary = true;
            } else {
              tab.primary = false;
            }
            tab.id = i + 1;
            tab.text = String(tab.text);
            tab.variable = String(tab.variable);
            tab.value = String(tab.value);
            tab.result = String(tab.result);
            tab.style = String(tab.style);
            tab.title = String(tab.title);
            tab.icon = String(tab.icon);
            if (tab.url !== undefined && tab.url !== null) {
              tab.url = replaceVariables(String(tab.url));
            } else {
              tab.url = 'null';
            }
            if (tab.updateRoute === undefined) {
              tab.updateRoute = false;
            }
            tab.useBack = useBack;
            tab.varBack = String(varBack);
            tab.varBack1 = String(varBack1);
            if (tabModeEnable) {
              if (i >= initialTab && i < finalTab) {
                buttons.push(tab);
              }
            } else {
              buttons.push(tab);
            }
          } else if (typeof type !== 'undefined' && type === 'button') {
            var button: GenericButtonData = {
              text: series.fields.find(field => field.name === options.buttonTextOption)?.values.get(i),
              variable: series.fields.find(field => field.name === options.buttonVariableOption)?.values.get(i),
              value: series.fields.find(field => field.name === options.buttonValueOption)?.values.get(i),
              icon: series.fields.find(field => field.name === options.buttonIconOption)?.values.get(i),
              url: series.fields.find(field => field.name === options.buttonUrlOption)?.values.get(i),
              updateRoute: series.fields.find(field => field.name === options.buttonUpdateUrlRoute)?.values.get(i),
              title: series.fields.find(field => field.name === options.buttonTitleOption)?.values.get(i),
              style: series.fields.find(field => field.name === options.buttonStyleOption)?.values.get(i),
			  target: series.fields.find(field => field.name === options.buttonTargetOption)?.values.get(i) || '_self',
              useBack: false,
              varBack: 'null',
              varBack1: 'null',
            };
            button.id = i + 1;
            button.text = String(button.text);
            button.variable = String(button.variable);
            button.value = String(button.value);
            button.style = String(button.style);
            button.title = String(button.title);
            button.icon = String(button.icon);
            if (button.url !== undefined && button.url !== null) {
              button.url = replaceVariables(String(button.url));
            } else {
              button.url = 'null';
            }
            if (button.updateRoute === undefined) {
              button.updateRoute = false;
            }
            button.useBack = useBack;
            button.varBack = String(varBack);
            button.varBack1 = String(varBack1);
            buttons2.push(button);
          } else if (typeof type !== 'undefined' && type === 'indicator') {
            let indicator: GenericIndicatorData = {
              text: series.fields.find(field => field.name === options.buttonTextOption)?.values.get(i),
              result: series.fields.find(field => field.name === options.buttonResultOption)?.values.get(i),
              value: series.fields.find(field => field.name === options.buttonValueOption)?.values.get(i),
              url: series.fields.find(field => field.name === options.buttonUrlOption)?.values.get(i),
              updateRoute: series.fields.find(field => field.name === options.buttonUpdateUrlRoute)?.values.get(i),
              title: series.fields.find(field => field.name === options.buttonTitleOption)?.values.get(i),
              style: series.fields.find(field => field.name === options.buttonStyleOption)?.values.get(i),
			  target: series.fields.find(field => field.name === options.buttonTargetOption)?.values.get(i) || '_self',
              useBack: false,
              varBack: 'null',
              varBack1: 'null',
            };
            indicator.id = i + 1;
            indicator.text = String(indicator.text);
            indicator.result = String(indicator.result);
            indicator.value = String(indicator.value);
            indicator.style = String(indicator.style);
            indicator.title = String(indicator.title);
            if (indicator.url !== 'undefined' && indicator.url !== null) {
              indicator.url = replaceVariables(String(indicator.url));
            } else {
              indicator.url = 'null';
            }
            if (indicator.updateRoute === undefined) {
              indicator.updateRoute = false;
            }
            indicator.useBack = useBack;
            indicator.varBack = String(varBack);
            indicator.varBack1 = String(varBack1);
            buttons3.push(indicator);
          }
        }
      }
    });

    if ((options.backButton || options.closeButton) && tabModeEnable) {
      let back_tab: TabsData = {
        text: options.closeButton ? 'Cerrar' : replaceVariables(options.backButtonText),
        variable: options.closeButton ? 'Cerrar' : 'Volver',
        value: options.closeButton ? 'Cerrar' : 'Volver',
        icon: options.closeButton ? 'times-circle' : 'arrow-bar-left',
        url: options.closeButton ? 'close' : '/d/home',
        title: options.closeButton ? 'Cerrar' : replaceVariables(options.backButtonText),
        style: options.backButtonStyle || 'default',
		target: '_self',
        useBack: false,
        varBack: 'null',
        varBack1: 'null',
      };
      if (options.backButton) {
		back_tab.url = replaceVariables(options.backButtonUrl);
        back_tab.useBack = useBack;
        back_tab.varBack = String(varBack);
        back_tab.varBack1 = String(varBack1);
	  }
      backAuxTab.push(back_tab);
    } else if (options.backButton || options.closeButton) {
      var aux_backButton: GenericButtonData = {
        text: '',
        variable: options.closeButton ? 'Cerrar' : 'Volver',
        value: options.closeButton ? 'Cerrar' : 'Volver',
        icon: options.closeButton ? 'times-circle' : 'arrow-bar-left',
        url: options.closeButton ? 'close' : '/d/home',
        title: options.closeButton ? 'Cerrar' : replaceVariables(options.backButtonText),
        style: options.backButtonStyle || 'default',
		target: '_self',
        useBack: false,
        varBack: 'null',
        varBack1: 'null',
      };
      if (options.backButton) {
	    aux_backButton.url = replaceVariables(options.backButtonUrl);
        aux_backButton.useBack = useBack;
        aux_backButton.varBack = String(varBack);
        aux_backButton.varBack1 = String(varBack1);
	  }
      backAuxButton.push(aux_backButton);
    }

    if (options.rotateButton) {
      var rotate_button: GenericButtonData = {
        text: 'Rotar',
        variable: options.defaultVariable,
        value: options.defaultValue || 'Rotar',
        icon: 'rotate-360',
        url: replaceVariables(options.rotateButtonUrl) || '#',
        title: 'Rotar entre pestañas',
        style: options.backButtonStyle || 'default',
		target: '_self',
        useBack: false,
        varBack: 'null',
        varBack1: 'null',
      };
      rotate_button.variable = replaceVariables(rotate_button.variable);
      rotate_button.value = replaceVariables(rotate_button.value) || '';
      rotate_button.useBack = useBack;
      rotate_button.varBack = String(varBack);
      rotate_button.varBack1 = String(varBack1);
      buttons2.push(rotate_button);
    }
	return { leftAuxButton, rightAuxButton, buttons, buttons2, buttons3, backAuxTab, backAuxButton };
  }, [data, options, replaceVariables, initialTab, maxTabContainerWidth, initialCalculationsDone]);

  if (tabsWidth < maxTabContainerWidth && initialTab > 0) {
	setInitialTab(0);
	finalTab = initialTab + tabsWindow;
	showLeftTab = false;
	showRightTab = false;
  }

  return (
    <div>
      {!tabModeEnable && (
        <div className={drop_container}>
          {(options.backButton || options.closeButton) && (
		    <div className="drop_backButton_container">{buttonFactory4(generateTabs.backAuxButton)}</div>
		  )}
          {generateTabs.buttons.length > 0 && (
		    <TabsSelect TabsData={generateTabs.buttons} />
		  )}
        </div>
      )}
      {tabModeEnable && (
        <div className="tab_panel_container">
          <div className="tab_pagination">
            <div className="tab_container">
              {(options.backButton || options.closeButton) && (
                <>
                  {buttonFactory1(generateTabs.backAuxTab)}
                  <div className="separadorH width_30px"></div>
				</>
              )}
              {showLeftTab && (
                <>
				  <div
                    className="tab_auxbutton_container"
                    onClick={() => {
                      if (initialTab > 0) {
                        setInitialTab(initialTab - 1);
                      }
                    }}
                  >
                    {buttonFactory5(generateTabs.leftAuxButton)}
                  </div>
				  <div className="width_3px"></div>
				</>
              )}
              <>
                {buttonFactory1(generateTabs.buttons)}
              </>
              {showRightTab && (
				<>
				  <div className="width_3px"></div>
                  <div
                    className="tab_auxbutton_container"
                    onClick={() => {
                      if (finalTab < tabsCount) {
                        setInitialTab(initialTab + 1);
                      }
                    }}
                  >
                    {buttonFactory5(generateTabs.rightAuxButton)}
                  </div>
				</>
              )}
            </div>
            {generateTabs.buttons2.length > 0 && (
			<>
			  <div className={separador_h}></div>
                <div className="tab_button_container">
                  {buttonFactory2(generateTabs.buttons2)}
                </div>
			  </>
			)}
			{generateTabs.buttons3.length > 0 && (
			  <div className={isDark ? 'tab_indicator_container_dark' : 'tab_indicator_container'}>
				{buttonFactory3(generateTabs.buttons3)}
			  </div>
			)}
          </div>
          <div className={separador_v}></div>
        </div>
      )}
    </div>
  );
});

function buttonFactory1(buttons: TabsData[]) {
  return buttons.map(tab => (
    <TabsButton
      id={tab.id}
      key={tab.text}
      text={tab.text}
      icon={tab.icon}
      variable={tab.variable}
      value={tab.value || ''}
      result={tab.result || ''}
      primary={tab.primary}
      style={tab.style}
      url={tab.url || 'null'}
	  target={tab.target || '_self'}
      updateRoute={tab.updateRoute}
      title={tab.title}
      useBack={tab.useBack}
      varBack={tab.varBack}
      varBack1={tab.varBack1}
    />
  ));
}

function buttonFactory2(buttons2: GenericButtonData[]) {
  return buttons2.map(button => (
    <GenericButton
      id={button.id}
      key={button.text}
      icon={button.icon}
      variable={button.variable}
      value={button.value || ''}
      title={button.title}
      style={button.style}
      url={button.url || 'null'}
	  target={button.target}
      updateRoute={button.updateRoute}
      useBack={button.useBack}
      varBack={button.varBack}
      varBack1={button.varBack1}
    />
  ));
}

function buttonFactory3(buttons3: GenericIndicatorData[]) {
  return buttons3.map(indicator => (
    <GenericIndicator
      key={indicator.text}
      result={indicator.result}
      value={indicator.value || ''}
      title={indicator.title}
      style={indicator.style}
      url={indicator.url || 'null'}
	  target={indicator.target}
      updateRoute={indicator.updateRoute}
      useBack={indicator.useBack}
      varBack={indicator.varBack}
      varBack1={indicator.varBack1}
    />
  ));
}

function buttonFactory4(buttons4: GenericButtonData[]) {
  return buttons4.map(button => (
    <BackButton
      id={button.id}
      key={button.text}
      icon={button.icon}
      variable={button.variable}
      value={button.value || ''}
      title={button.title}
      style={button.style}
      url={button.url || 'null'}
	  target={button.target}
      updateRoute={button.updateRoute}
      useBack={button.useBack}
      varBack={button.varBack}
      varBack1={button.varBack1}
    />
  ));
}

function buttonFactory5(buttons: TabsData[]) {
  return buttons.map(tab => (
    <TabsButtonAux
      id={tab.id}
      key={tab.text}
      text={tab.text}
      icon={tab.icon}
      variable={tab.variable}
      value={tab.value || ''}
      result={tab.result || ''}
      primary={tab.primary}
      style={tab.style}
      url={tab.url || 'null'}
	  target={tab.target}
      updateRoute={tab.updateRoute}
      title={tab.title}
      useBack={tab.useBack}
      varBack={tab.varBack}
      varBack1={tab.varBack1}
    />
  ));
}