import { css } from '@emotion/css';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { NetMonitorTheme2 } from '@grafana/data';
import { getTemplateSrv } from '@grafana/runtime';
import { Alert, LoadingPlaceholder, useStyles2, Icon } from '@grafana/ui';
import { contextSrv } from 'app/core/services/context_srv';
import { HelpData } from './L1OyMManager';
import { HelpGroup } from './HelpGroup';
import { valueToSubtype } from './types';

export const helpManagerPanel: React.FC<Props> = React.memo(({ options, replaceVariables, id, width, height, data }) => {
  const isNetMonitorAdmin = contextSrv.isNetMonitorAdmin;
  const isAdmin = options.editorCanAdmin && contextSrv.isEditor ? true : isNetMonitorAdmin;
  const userName = contextSrv.user.name;
  const imageServerUrl = replaceVariables(options.imageServer);
  const maxImageSize = isNaN(replaceVariables(options.maxImageSize)) ? 10 : Number(replaceVariables(options.maxImageSize));
  const maxFileSize = isNaN(replaceVariables(options.maxFileSize)) ? 10 : Number(replaceVariables(options.maxFileSize));
  const pluginTitle = replaceVariables(options.pluginTitle);
  const okMessage = replaceVariables(options.successMessage);
  const noAlertMessage = replaceVariables(options.noAlertMessage) || 'No existen elementos';
  const error4 = replaceVariables(options.error4);
  const pluginApi = replaceVariables(options.api);
  const uidVariableValue = options.uidVariable ? getTemplateSrv().replace(`$${options.uidVariable}`) : '';
  const siteIdVariableValue = options.canAddNotes && options.siteIdVariable ?
    getTemplateSrv().replace(`$${options.siteIdVariable}`) : '';
  const assetIdVariableValue = options.canAddNotes && options.assetIdVariable ?
    getTemplateSrv().replace(`$${options.assetIdVariable}`) : '';
  const dashIdVariableValue = options.canAddNotes && options.dashboardIdVariable ?
    getTemplateSrv().replace(`$${options.dashboardIdVariable}`) : '';
  const alarmIdVariableValue = options.canAddNotes && options.alarmIdVariable ?
    getTemplateSrv().replace(`$${options.alarmIdVariable}`) : '';
  const typeVariableValue = options.typeVariable ? getTemplateSrv().replace(`$${options.typeVariable}`) : '';
  const hashVariableValue = options.hashVariable ? getTemplateSrv().replace(`$${options.hashVariable}`) : '';
  const hashArray = hashVariableValue ? decodeURIComponent(hashVariableValue) : '[]';
  const [actualUidValue, setActualUidValue] = useState(uidVariableValue);
  const [actualDashIdValue, setActualDashIdValue] = useState(dashIdVariableValue);
  const [actualAlarmIdValue, setActualAlarmIdValue] = useState(alarmIdVariableValue);
  const [actualAssetIdValue, setActualAssetIdValue] = useState(assetIdVariableValue);
  const [actualSiteIdValue, setActualSiteIdValue] = useState(siteIdVariableValue);
  const [actualTypeValue, setActualTypeValue] = useState(typeVariableValue);
  const [elementsConfigured, setElementsConfigured] = useState<HelpData[]>([]);
  const filterToTrack = replaceVariables(options.variableToTrack);
  const [valueTracked, setValueTracked] = useState(filterToTrack);
  const [renderCount, setRenderCount] = useState(0);

  const isAddNoteMode = options.canAddNotes && typeVariableValue === 'notes' && uidVariableValue === 'newId' ? true : false;
  const isViewNoteMode = typeVariableValue === 'notes' && uidVariableValue !== 'newId' ? true : false;
  const isAddSiteDocMode = options.canAddNotes && typeVariableValue === 'sitedoc' && uidVariableValue === 'newId' && 
	(siteIdVariableValue !== '' || assetIdVariableValue !== '') ? true : false;
  const isViewSiteDocMode = typeVariableValue === 'sitedoc' && uidVariableValue !== 'newId' ? true : false;
  const siteDocType = isAddSiteDocMode && siteIdVariableValue !== '' ? 'emp' : 'asset';
  const noteType = isAddNoteMode && dashIdVariableValue !== '' ? 'dash' : 'alarm';

  const memoizedOptions = useMemo(() => ({ 
    data: data,
  }), [data]);

  const styles = useStyles2(getStyles(width, height));

  const subTypeToType = (value: string) => {
	const valueSelected = valueToSubtype.find(aspect => aspect.value === value);
	if (valueSelected) {
	  return valueSelected.family;
	} else {
	 return 'Unknown';
	}
  }

  const newElementsConfigured: HelpData[] = [];

  if (isAddNoteMode || isAddSiteDocMode) {
    const d = Date.now();
	const newId = generarID();
	let newNote = {
	  id: 0,
	  uid: newId,
	  title: isAddNoteMode ? 'Nueva Nota' : 'Nueva documentación de sitio',
	  docId: newId,
	  docType: isAddNoteMode ? 'notes' : 'sitedoc',
	  subType: isAddNoteMode ? noteType : siteDocType,
	  status: 'in_use',
	  state: true,
	  category: isAddNoteMode ? 'L1' : 'oym',
	  chapter: '',
	  section: '',
	  technicalDetails: '',
	  content: '',
	  lastEditor: userName,
	  editionNotes: '',
	  mediaData: '',
	  imagePaths: [],
	  imageLabels: [],
	  imageFiles: [],
	  relatedArticles: '',
	  fileLabels: [],
	  filePaths: [],
	  fileFiles: [],
	  references: '',
	  assetId: isAddSiteDocMode && assetIdVariableValue !== '' ? assetIdVariableValue : '',
	  placeId: isAddSiteDocMode && siteIdVariableValue !== '' ? siteIdVariableValue : '',
	  hashIds: hashArray,
	  panelId: isAddNoteMode && dashIdVariableValue !== '' ? dashIdVariableValue : '',
	  alarmId: isAddNoteMode && alarmIdVariableValue !== '' ? alarmIdVariableValue : '',
	  user: userName,
	  created: '',
	  updated: '',
	  version: 0,
	  wasChange: false,
	};
	newElementsConfigured.push(newNote);
  } else {
    memoizedOptions.data.series.forEach(series => {
	  const helpDocs: GraphSeriesValue[] = series.fields[0] ? series.fields[0].values.toArray() : [];
	  for (let i = 0; i < helpDocs.length; i++) {
	    let newElement = {
		  id: i,
		  uid: series.fields.find(field => field.name === options.uid)?.values.get(i),
		  title: series.fields.find(field => field.name === options.title)?.values.get(i),
		  docId: '',
		  docType: '',
		  subType: '',
		  status: 'under_construction',
		  state: false,
		  category: '',
		  chapter: '',
		  section: '',
		  technicalDetails: series.fields.find(field => field.name === options.technicalDetails)?.values.get(i),
		  content: '',
		  lastEditor: '',
		  editionNotes: '',
		  references: series.fields.find(field => field.name === options.reference)?.values.get(i),
		  assetId: '',
		  placeId: '',
		  hashIds: '',
		  panelId: '',
		  alarmId: '',
		  mediaData: '',
		  imagePaths: [],
		  imageLabels: [],
		  imageFiles: [],
		  relatedArticles: '',
		  fileLabels: [],
		  filePaths: [],
		  fileFiles: [],
		  user: series.fields.find(field => field.name === options.user)?.values.get(i),
		  created: series.fields.find(field => field.name === options.created_at)?.values.get(i),
		  updated: series.fields.find(field => field.name === options.updated_at)?.values.get(i),
		  version: Number(series.fields.find(field => field.name === options.version)?.values.get(i)),
		  wasChange: false,
	    };
	    try {
		  const jsonObject = JSON.parse(String(newElement.technicalDetails));
	      newElement.docId = jsonObject.technical_details.docId || '';
	      newElement.status = jsonObject.technical_details.status || 'disable';
	      newElement.state = newElement.status === 'disable' ? false : true;
	      newElement.subType = jsonObject.technical_details.type || '';
	      const docType = subTypeToType(newElement.subType);
	      newElement.docType = docType;
	      if (docType === 'notes') {
	        newElement.alarmId = jsonObject.technical_details.alarm_id || '';
		    newElement.panelId = jsonObject.technical_details.panel_id || '';
	      } else if (docType === 'sitedoc') {
	        newElement.placeId = jsonObject.technical_details.place_id || '';
		    newElement.assetId = jsonObject.technical_details.asset_id || '';
	      }
	      newElement.category = jsonObject.technical_details.category || '';
	      newElement.chapter = jsonObject.technical_details.chapter || '';
	      newElement.section = jsonObject.technical_details.section || '';
	      newElement.content = jsonObject.technical_details.content ? jsonObject.technical_details.content.markdownContent : '';
	      newElement.references = jsonObject.technical_details.references || '';
	      newElement.lastEditor = jsonObject.technical_details.edition_data.last_editor || '';
	      newElement.editionNotes = jsonObject.technical_details.edition_data.notes || '';
	      newElement.mediaData = jsonObject.technical_details.edition_data.media || '';
		  const images = newElement.mediaData !== '' ? newElement.mediaData.images : '';
		  let imagePaths: [] = [];
		  let imageLabels: [] = [];
		  let imageFiles: [] = [];
		  if (images !== '') {
		    images.forEach(image => {
		      imagePaths.push(image.path);
		      imageLabels.push(image.label);
		      imageFiles.push(image.file);
		    });
		  }
		  newElement.imagePaths = imagePaths;
		  newElement.imageLabels = imageLabels;
		  newElement.imageFiles = imageFiles;
	      newElement.relatedArticles = jsonObject.technical_details.related_articles || '';
		  const files = newElement.relatedArticles !== '' ? newElement.relatedArticles.files : '';
		  let filePaths: [] = [];
		  let fileLabels: [] = [];
		  let fileFiles: [] = [];
		  if (files !== '') {
		    files.forEach(file => {
		      filePaths.push(file.path);
		      fileLabels.push(file.label);
		      fileFiles.push(file.file);
		    });
	 	  }
		  newElement.filePaths = filePaths;
		  newElement.fileLabels = fileLabels;
		  newElement.fileFiles = fileFiles;
		} catch (error) {
		  console.log('Error en formato JSON: ', newElement.technicalDetails, error);
		  newElement.created = '';
		}
	    const created_at = Number(newElement.created);
	    try {
		  newElement.created = moment(created_at).format('DD-MM-YY HH:mm:ss');
		} catch (error) {
		  console.log('Error en formato de fecha del registro: ', newElement.uid, error);
		  newElement.created = '';
		}
	    const updated_at = Number(newElement.updated);
	    try {
		  newElement.updated = moment(updated_at).format('DD-MM-YY HH:mm:ss');
		} catch (error) {
		  console.log('Error en formato de fecha del registro: ', newElement.uid, error);
		  newElement.updated = '';
		}
	    newElementsConfigured.push(newElement);
	  }
    });
  }

  const pluginVariables = [
    pluginApi,
	okMessage,
	noAlertMessage,
	options.saniticeDoc,
	options.enablePreview,
	options.previewOnly,
	options.uidVariable,
	options.viewerMode,
	options.canAddNotes,
	isAddNoteMode,
	options.alarmIdVariable,
	options.typeVariable,
	options.hashVariable,
	isViewNoteMode,
	isViewSiteDocMode,
	isAddSiteDocMode,
	options.dashboardIdVariable,
	options.assetIdVariable,
	imageServerUrl,
	maxImageSize,
	maxFileSize
  ];

  if (
    renderCount === 0 || 
    valueTracked !== filterToTrack || 
    uidVariableValue !== actualUidValue || 
	alarmIdVariableValue !== actualAlarmIdValue ||
	dashIdVariableValue !== actualDashIdValue ||
	siteIdVariableValue !== actualSiteIdValue ||
	assetIdVariableValue !== actualAssetIdValue ||
	typeVariableValue !== actualTypeValue
  ) {
	let elementFiltered = newElementsConfigured;
	if (!isAddNoteMode && !isAddSiteDocMode) {
	  if (typeVariableValue !== 'notes' && typeVariableValue !== 'sitedoc') {
	    if (!isAdmin && uidVariableValue !== 'newId' && options.viewerMode) {
		  elementFiltered = newElementsConfigured.filter(element => element.uid === uidVariableValue);
	    }
      } else if (typeVariableValue === 'notes') {
	    if (uidVariableValue !== 'newId') {
	      if (uidVariableValue !== '' && alarmIdVariableValue === '' && dashIdVariableValue === '') {
		    elementFiltered = newElementsConfigured.filter(element => element.uid === uidVariableValue);
	      } else if (alarmIdVariableValue !== '') {
	        elementFiltered = newElementsConfigured.filter(element => element.alarmId === alarmIdVariableValue && element.docType === 'notes');
	      } else if (dashIdVariableValue !== '') {
	        elementFiltered = newElementsConfigured.filter(element => element.panelId === dashIdVariableValue && element.docType === 'notes');
	      } else {
		    elementFiltered = newElementsConfigured.filter(element => element.docType === 'notes');
		  }
		}
      } else if (typeVariableValue === 'sitedoc') {
	    if (uidVariableValue !== 'newId') {
	      if (uidVariableValue !== '' && siteIdVariableValue === '' && assetIdVariableValue === '') {
	        elementFiltered = newElementsConfigured.filter(element => element.uid === uidVariableValue);
	      } else if (siteIdVariableValue !== '') {
	        elementFiltered = newElementsConfigured.filter(element => element.placeId === siteIdVariableValue && element.docType === 'sitedoc');
	      } else if (assetIdVariableValue !== '') {
	        elementFiltered = newElementsConfigured.filter(element => element.assetId === assetIdVariableValue && element.docType === 'sitedoc');
	      } else {
		    elementFiltered = newElementsConfigured.filter(element => element.docType === 'sitedoc');
		  }
		}
	  }
    }

	let elementInUse = elementFiltered;
	if (options.showOnlyInUse) {
	  elementInUse = elementFiltered.filter(element => element.status === 'in_use');
	} else if (!isAddNoteMode && !isAddSiteDocMode) {
	  elementInUse = elementFiltered.filter(element => element.version > 0);
	}
	let elementsToShow = elementInUse;
	if (!isNetMonitorAdmin) {
	  if (typeVariableValue === 'notes' || typeVariableValue === 'sitedoc' || typeVariableValue === 'help') {
	    elementsToShow = elementInUse.filter(element => element.docType === typeVariableValue);
	  } else if (typeVariableValue === 'Todos' || typeVariableValue === 'todos') {
	    elementsToShow = elementInUse.filter(element => element.docType !== 'internal');
	  }
	} else if (typeVariableValue === 'sitedoc' || typeVariableValue === 'notes' ||
	  typeVariableValue === 'help' || typeVariableValue === 'internal') {
	  elementsToShow = elementInUse.filter(element => element.docType === typeVariableValue);
	}

	if (elementsToShow) {
	  setElementsConfigured(elementsToShow);
	} else {
	  setElementsConfigured([]);
	}
	setValueTracked(filterToTrack);
	setActualUidValue(uidVariableValue);
	setActualAlarmIdValue(alarmIdVariableValue);
	setActualDashIdValue(dashIdVariableValue);
	setActualAssetIdValue(assetIdVariableValue);
	setActualSiteIdValue(siteIdVariableValue);
	setActualTypeValue(typeVariableValue);
	setRenderCount(renderCount + 1);
  }

  const noAlertsMessage = elementsConfigured.length === 0 ? noAlertMessage : undefined;

  if (width < 250 || height < 150) {
    return (
	  <div className="alertListErrorContainer" title={error4}>
	    <Icon name={'cloud-slash'} size="xxl" />
	  </div>
	);
  }
  if (data.state === 'Error') {
    return (
	  <div className="alertListErrorContainer" title={noAlertsMessage}>
	    <Icon name={'sync-slash'} size="xxl" />
	  </div>
	);
  }

  return (
    <div className={styles.section} key={String(id)}>
      <HelpGroup
	    id={id + '_' + renderCount}
	    showTitle={options.showTitle}
		showSelector={options.showSelector}
		showTable={options.showTableMode}
	    pluginTitle={pluginTitle}
	    isAdmin={isAdmin}
	    width={width}
		height={height}
	    elements={elementsConfigured}
		pluginVariables={pluginVariables}
		onSaveChanges={(elementSaved) => {
		  const actualElements = elementsConfigured;
		  const element = elementSaved.find(element => element.updated === '');
		  const index = element ? element.id : null;
		  if (index !== null) {
			const d = Date.now();
			const date = moment(d).format('DD/MM/YYYY HH:mm:ss');
			let newElement = element;
			newElement.updated = date;
			const jsonContent = JSON.parse(String(element.content));
			newElement.content = jsonContent.markdownContent || '';
			actualElements[index] = newElement;
		    setElementsConfigured(actualElements);			
		  }
		  return actualElements;
		}}
	  />
    </div>
  );
}, (prevProps, nextProps) => {
  return prevProps.data === nextProps.data;
});

const generarID = () => {
  const caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let id = '';
  for (let i = 0; i < 10; i++) {
    id += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
  }
  return id;
};

const getStyles = (width: number, height: number) => {
  return (theme: NetMonitorTheme2) => ({
    section: css`
	  width: 100%;
	  height: 100%;
    `,
  });
};
